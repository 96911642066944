import DynaQueryForm from "@/components/DynaQueryForm";
import DialogView from "@/components/DialogView";
import path from "@/api/path";
import api from "@/api";
export default {
  name: "BatterTableDataView",
  components: {
    DynaQueryForm,
    DialogView
  },
  props: {
    maxPageCnt: {
      type: Number,
      default: 7
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false
    },
    pageSizeChoices: {
      type: Array,
      default: () => {
        return [10, 20, 30, 40, 50, 100];
      }
    },
    tableId: {
      type: Number,
      default: 0
    },
    initQuery: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      createDialogVisible: false,
      editDialogVisible: false,
      detailDialogVisible: false,
      editBindId: 0,
      detailBindId: 0,
      query: {},
      form: {},
      total: 0,
      page: 1,
      pageSize: 10,
      tableDataLoading: false,
      tableData: {
        title: "...",
        header: [],
        list: [],
        textStyleMapping: {},
        textMapping: {}
      },
      tableParams: {
        queryForm: 0,
        list: null,
        create: null,
        update: null,
        delete: null,
        detailDialog: 0,
        createDialog: 0,
        updateDialog: 0,
        updateKey: null,
        detailKey: null,
        deleteKey: null
      },
      queryFormVersion: 0,
      queryFormHeight: 'auto'
    };
  },
  watch: {
    initQuery(a, b) {
      let diff = false;
      for (let k of Object.keys(a)) {
        if (a[k] !== b[k]) {
          diff = true;
        }
      }
      if (diff) {
        this.fetchData();
      }
      // this.$emit('onInitQuery', this.initQuery);
    }
  },

  methods: {
    indexValueMethod(i) {
      return (this.page - 1) * this.pageSize + i + 1;
    },
    initParams() {
      this.pageSize = this.pageSizeChoices[0];
      this.tableParams = path.table[this.tableId];
    },
    createData(data) {
      for (let k of Object.keys(this.initQuery)) {
        if (this.initQuery[k]) {
          data[k] = this.initQuery[k];
        }
      }
      api.post(this.tableParams.create, data).then(() => {
        this.createDialogVisible = false;
        this.createDialogDataId = 0;
        this.fetchData();
      });
    },
    updateData(data) {
      data[this.tableParams.updateKey] = this.editBindId;
      api.post(this.tableParams.update, data).then(() => {
        this.editDialogVisible = false;
        this.editBindId = 0;
        this.fetchData();
      });
    },
    addMore() {
      if (this.tableParams.create && this.tableParams.createDialog) {
        this.createDialogVisible = true;
      } else {
        this.$emit('addMore');
      }
    },
    onEditData(data) {
      if (this.tableParams.update) {
        this.editDialogVisible = true;
        this.editBindId = data['id'];
      } else {
        this.$emit('onEditData', data);
      }
    },
    onDetailData(data) {
      if (this.tableParams.detailKey) {
        this.detailDialogVisible = true;
        this.detailBindId = data['id'];
      } else {
        this.$emit('onDetailData', data);
      }
    },
    onDeleteData(data) {
      let deleteQuery = {};
      deleteQuery[this.tableParams.deleteKey] = data['id'];
      api.get(this.tableParams.delete, deleteQuery).then(() => {
        this.fetchData();
      });
    },
    gotoPage(e) {
      this.page = e;
      this.fetchData();
    },
    onSizeChange(e) {
      this.page = 1;
      this.pageSize = e;
      this.fetchData();
    },
    onQuery(data) {
      this.query = data;
      this.fetchData();
      this.$emit('onQuery', data);
    },
    onSortChange(data) {
      this.query.orderBy = data.prop;
      this.query.orderDesc = data.order !== 'ascending';
      this.fetchData();
      this.$emit('onQuery', data);
    },
    onResetQuery() {
      this.queryFormHeight = `${this.$refs.queryFormContainer.clientHeight}px`;
      this.queryFormVersion++;
      this.query = {};
      this.$refs.icetable.clearSort();
      this.fetchData();
      this.$emit('onResetQuery', {});
    },
    fetchData() {
      this.tableDataLoading = true;
      for (let k of Object.keys(this.initQuery)) {
        if (this.initQuery[k] != null) {
          this.query[k] = this.initQuery[k];
        }
      }
      this.query['page'] = this.page - 1;
      this.query['pageSize'] = this.pageSize;
      if (this.tableParams.listMethod === 'post') {
        api.post(this.tableParams.list, this.query).then(ret => {
          this.tableDataLoading = false;
          this.tableData = ret;
          this.total = ret.total;
          this.page = ret.page + 1;
          this.pageSize = ret.pageSize;
          this.$emit('onFetchData', ret);
        }).catch(() => {
          this.tableDataLoading = false;
        });
      } else {
        api.get(this.tableParams.list, this.query).then(ret => {
          this.tableDataLoading = false;
          this.tableData = ret;
          this.total = ret.total;
          this.page = ret.page + 1;
          this.pageSize = ret.pageSize;
          this.$emit('onFetchData', ret);
        }).catch(() => {
          this.tableDataLoading = false;
        });
      }
    }
  },
  mounted() {
    this.initParams();
    this.fetchData();
    this.$emit('onInitQuery', this.initQuery);
  }
};