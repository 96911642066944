import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
import { ElMessage } from "element-plus";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "UserListView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  methods: {
    downloadTplFile() {
      window.open('https://cdn.waityou.online/7880d8cc-26ba-9173-4d94-25694e16b26c.xlsx', '_blank');
    },
    onUploadedFile(url) {
      api.importUserFromFile(url).then(ret => {
        ElMessage.success(ret);
        this.importUserVisible = false;
        this.$refs.userTable.fetchData();
      });
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.accountState == 'NORMAL';
        this.loginMap[itm.id] = itm.enableManagerLogin;
      }
    },
    enableChange(id, val) {
      api.updateUserEnableState(id, val).then(() => {
        this.enableMap[id] = val;
      });
    },
    enableManagerLoginChange(id, val) {
      api.updateUserManagerLogin(id, val).then(() => {
        this.loginMap[id] = val;
      });
    }
  },
  data() {
    return {
      importUserVisible: false,
      enableMap: {},
      statisticsMap: {},
      loginMap: {},
      crumb: [{
        name: '用户列表'
      }],
      initQuery: {
        userId: this.$route.query.userId
      }
    };
  }
};