import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4c3cf226"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "EditableFileIconContainer"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  src: "https://cdn.waityou.online/db6739d5-f632-fdc3-cc7a-64ce388ab594.svg",
  class: "sqr24",
  style: {
    "position": "absolute",
    "right": "-8px",
    "top": "-8px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: $props.customIcon ? $props.customIcon : $options.fileIcon(),
    class: "sqr64"
  }, null, 8, _hoisted_2), $props.share ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), !$props.noDeleteBtn ? (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: "https://cdn.waityou.online/93240b06-8788-cf88-b56f-eadcd6cf8a51.svg",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('remove')),
    class: "sqr24",
    style: {
      "position": "absolute",
      "right": "-8px",
      "top": "-8px",
      "background-color": "white",
      "border-radius": "12px"
    }
  })) : _createCommentVNode("", true)]);
}