import OrgSelector from "@/components/catalog/OrgSelector";
import OrgPeopleList from "@/components/catalog/OrgPeopleList";
import SearchedPeopleList from "@/components/catalog/SearchedPeopleList";
import api from "@/api";
import { ElMessage } from "element-plus";
import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "VersionCooEditAuthView",
  components: {
    OrgSelector,
    OrgPeopleList,
    DialogCustomView,
    SearchedPeopleList
  },
  props: {
    versionId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      cooEditLinkVisible: false,
      selectedOrgId: null,
      cooEditLink: null,
      orgTree: null,
      nameSearch: null,
      searchedUsers: null
    };
  },
  methods: {
    doSearch() {
      if (!this.nameSearch || this.nameSearch.length < 1) {
        ElMessage.error('请输入要搜索的姓名');
        return;
      }
      api.searchCooEditUser({
        name: this.nameSearch,
        versionId: this.versionId
      }).then(ret => {
        this.searchedUsers = ret;
      });
    },
    copyInviteLink() {
      // 检查浏览器是否支持Clipboard API
      if (navigator.clipboard) {
        // 将文本写入剪贴板
        navigator.clipboard.writeText(this.cooEditLink).then(() => {
          ElMessage.success('协作链接复制成功!');
        }).catch(() => {
          this.showCopyNotice(this.cooEditLink);
        });
      } else {
        this.showCopyNotice(this.cooEditLink);
      }
    },
    showCopyNotice(text) {
      this.cooEditLink = text;
      this.cooEditLinkVisible = true;
    },
    onOrgChange(oid) {
      this.selectedOrgId = oid;
      this.nameSearch = null;
      this.searchedUsers = null;
    },
    onCooEditOrgAuthChange() {
      if (this.$refs.orgPeopleList) {
        this.$refs.orgPeopleList.init();
      }
    },
    onAuthChange() {
      this.init();
    },
    init() {
      api.getCooEditConfig(this.versionId).then(ret => {
        this.cooEditLink = ret.cooEditLink;
        this.orgTree = ret.orgTree;
      });
    }
  },
  mounted() {
    this.init();
  }
};