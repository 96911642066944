export default {
  name: "CatalogDataInfoTable",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      preview: false
    };
  },
  methods: {
    previewImage() {}
  }
};