import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-73f2dd85"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_el_aside = _resolveComponent("el-aside");
  return _openBlock(), _createBlock(_component_el_aside, {
    class: "menu"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_scrollbar, null, {
      default: _withCtx(() => [_createVNode(_component_el_menu, {
        "default-openeds": $data.openedIds,
        "unique-opened": false,
        router: true,
        "default-active": _ctx.$route.path
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menus, menu => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: menu.id
          }, [menu.subMenus ? (_openBlock(), _createBlock(_component_el_sub_menu, {
            key: 0,
            index: menu.idStr
          }, {
            title: _withCtx(() => [menu.icon && menu.icon.startsWith('http') ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: menu.icon,
              class: "sqr18 mr4"
            }, null, 8, _hoisted_1)) : menu.icon ? (_openBlock(), _createBlock(_component_svg_icon, {
              key: 1,
              "icon-class": menu.icon,
              class: "menuIcon"
            }, null, 8, ["icon-class"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(menu.name), 1)]),
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.subMenus, sub => {
              return _openBlock(), _createBlock(_component_el_menu_item, {
                key: sub.idStr,
                index: sub.path,
                route: sub.path
              }, {
                default: _withCtx(() => [sub.icon && sub.icon.startsWith('http') ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: sub.icon,
                  class: "sqr18 mr4"
                }, null, 8, _hoisted_2)) : sub.icon ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 1,
                  "icon-class": sub.icon,
                  class: "menuIcon"
                }, null, 8, ["icon-class"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(sub.name), 1)]),
                _: 2
              }, 1032, ["index", "route"]);
            }), 128))]),
            _: 2
          }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
            key: 1,
            index: menu.path,
            route: menu.path
          }, {
            default: _withCtx(() => [menu.icon && menu.icon.startsWith('http') ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: menu.icon,
              class: "sqr18 mr4"
            }, null, 8, _hoisted_3)) : menu.icon ? (_openBlock(), _createBlock(_component_svg_icon, {
              key: 1,
              "icon-class": menu.icon,
              class: "menuIcon"
            }, null, 8, ["icon-class"])) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(menu.name), 1)]),
            _: 2
          }, 1032, ["index", "route"]))], 64);
        }), 128))]),
        _: 1
      }, 8, ["default-openeds", "default-active"])]),
      _: 1
    })]),
    _: 1
  });
}