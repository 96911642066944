import api from "@/api";
export default {
  name: "OrgSelectorItem",
  props: {
    versionId: {
      type: Number,
      default: 0
    },
    selectedOrgId: {
      type: Number,
      default: 0
    },
    isChildren: {
      type: Boolean,
      default: false
    },
    org: {
      type: Object,
      default: () => {
        return {
          id: -1,
          label: '指挥中心',
          data: {
            peopleCnt: 100
          },
          children: [{
            label: '下级1',
            data: {
              peopleCnt: 100
            }
          }]
        };
      }
    }
  },
  watch: {
    org() {
      this.init();
    }
  },
  data() {
    return {
      extend: false,
      checked: false
    };
  },
  methods: {
    onChange(e) {
      api.updateCooEditOrgAuth({
        versionId: this.versionId,
        orgId: this.org.id,
        auth: e
      }).then(changed => {
        if (changed > 0) {
          this.$emit('CooEditOrgAuthChange');
        }
      });
    },
    init() {
      this.checked = this.org.data.selectedCnt > 0;
    }
  },
  mounted() {
    this.init();
  }
};