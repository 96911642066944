import OrgPeopleItem from "@/components/catalog/OrgPeopleItem";
import api from "@/api";
export default {
  name: "OrgPeopleList",
  components: {
    OrgPeopleItem
  },
  props: {
    selectedOrgId: {
      type: Number,
      default: 0
    },
    versionId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      user: []
    };
  },
  methods: {
    init() {
      api.getCooEditOrgPeopleConfig({
        versionId: this.versionId,
        orgId: this.selectedOrgId
      }).then(list => {
        this.user = list;
      });
    }
  },
  mounted() {
    this.init();
  }
};