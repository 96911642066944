import {createStore} from 'vuex'
import api from "@/api"
import path from "@/api/path"
export default createStore({
    state: {
        user: {},
        contextMenu: {
            style: {
                top: '0px',
                left: '0px',
            },
            menus: [],
            visible: false,
            event: false,
        },
        locationPicker: [120.19732, 30.246026],
        viewType: 0,
        fetchCache:{},
    },
    getters: {
        fetchArrayData: (state) => (k) => {
            if (state.fetchCache[k]) {
                return state.fetchCache[k]
            }
            return []
        },
        fetchObjectData: (state) => (k) => {
            if (state.fetchCache[k]) {
                return state.fetchCache[k]
            }
            return {}
        },
    },
    mutations: {
        clearFetchCache(state){
            state.fetchCache = {}
        },
        updateFetchCache(state, payload) {
            state.fetchCache[payload.key] = payload.data;
        },
        switchViewType(state) {
            state.viewType = (state.viewType + 1) % 2;
        },
        showContextMenu(state, data) {
            state.contextMenu.menus = data.menus;
            state.contextMenu.style = data.style;
            state.contextMenu.visible = true;
        },
        hideContextMenu(state) {
            state.contextMenu.menus = [];
            state.contextMenu.visible = false;
        },
        updateUser(state, val) {
            state.user = val;
        },
        setLocationPicker(state, loc) {
            state.locationPicker = loc;
        },
        resetLocationPicker(state) {
            state.locationPicker = [120.19732, 30.246026]
        },
        dispatchContextMenuKey(state, k) {
            state.contextMenu.event = k;
            state.contextMenu.visible = false;
            setTimeout(() => {
                state.contextMenu.event = null;
            }, 200)
        },
        resetContextMenuKey(state) {
            state.contextMenu.event = null;
        }
    },
    actions: {

        reloadFetchCache(ctx, args) {
            api.get(path[args.path], args.query)
                .then(ret => {
                    ctx.commit('updateFetchCache', {key: args.key, data: ret})
                })
        }
    },
    modules: {}
})
