import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "paginationNumber"
};
const _hoisted_2 = {
  class: "paginationNumber"
};
const _hoisted_3 = {
  class: "paginationNumber"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  const _component_el_row = _resolveComponent("el-row");
  return $data.tableData && $data.tableData.total > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_renderSlot(_ctx.$slots, "default", {
    data: $data.tableData
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    },
    class: "colFlex mt8"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_config_provider, null, {
      default: _withCtx(() => [_createVNode(_component_el_pagination, {
        class: "mt8",
        background: "",
        "current-page": $data.page,
        "page-size": $data.pageSize,
        "pager-count": $props.maxPageCnt,
        "page-sizes": $props.pageSizeChoices,
        onSizeChange: $options.onSizeChange,
        onCurrentChange: $options.gotoPage,
        layout: "sizes,slot,->,prev, pager, next,jumper",
        total: $data.total
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, [_createTextVNode(" 当前第"), _createElementVNode("span", _hoisted_1, _toDisplayString(($data.page - 1) * $data.pageSize + $data.tableData.list.length), 1), _createTextVNode("/"), _createElementVNode("span", _hoisted_2, _toDisplayString($data.total), 1), _createTextVNode("项，共"), _createElementVNode("span", _hoisted_3, _toDisplayString(parseInt($data.total / $data.pageSize) + 1), 1), _createTextVNode("页")])]),
        _: 1
      }, 8, ["current-page", "page-size", "pager-count", "page-sizes", "onSizeChange", "onCurrentChange", "total"])]),
      _: 1
    })]),
    _: 1
  })], 64)) : _renderSlot(_ctx.$slots, "empty", {
    key: 1
  });
}