import axios from "axios";
import querystring from "querystring"
import {ElMessage} from 'element-plus'
import router from "@/router";

const instance = axios.create({
    timeout: 30000
})
const isNeedReLogin = code => {
    switch (code) {
        case 4001:
        case 5001:
        case 4007:
            return true;
        default:
            return false;
    }
}
// 注册发送拦截器
instance.interceptors.request.use(config => {
    config.headers['x-dreport-token'] = localStorage.getItem('access_token');

    if (config.methods === "post") {
        config.data = querystring.stringify(config.data);
    }
    return config;
}, error => {
    console.log(error);
    return Promise.reject(error);
})
// 注册响应拦截器
instance.interceptors.response.use(resp => {
        if (resp.status === 200 && resp.data.code === 0) {
            return Promise.resolve(resp.data.data);
        }
        if (isNeedReLogin(resp.data.code)) {
            router.replace({
                path: '/login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            })
        } else {
            ElMessage.error(resp.data.msg);
        }
        return Promise.reject(resp);
    },
    error => {
        return Promise.reject(error);
    }
)
export default instance;