export default {
  name: "NumberCard",
  props: {
    title: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: '份'
    },
    number: {
      type: Number,
      default: 0
    },
    fileSize: {
      type: String,
      default: null
    },
    yesterday: {
      type: Number,
      default: null
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};