import api from "@/api";
export default {
  name: "TaskDashboard",
  data() {
    return {
      tasks: []
    };
  },
  methods: {
    init() {
      api.getCooEditTaskList({
        page: 0,
        pageSize: 10
      }).then(ret => {
        this.tasks = ret.list;
      });
    }
  },
  mounted() {
    this.init();
  }
};