export default {
  name: "ShareFolderFileCard",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      activeId: 0
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};