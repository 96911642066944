import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "TaskListView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '任务管理'
      }]
    };
  },
  methods: {
    onDetailData(d) {
      this.$router.push('/m/task/detail/' + d.id);
    },
    onEditData(d) {
      this.$router.push('/m/task/create?taskId=' + d.id);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};