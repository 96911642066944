import "core-js/modules/es.array.push.js";
import BreadCrumb from "@/components/BreadCrumb";
import BatterPageDataView from "@/components/BatterPageDataView";
import IconFileList2 from "@/components/IconFileList2";
import FileOperateHead from "@/components/file/FileOperateHead";
import BatterTableDataView from "@/components/BatterTableDataView";
import FileOperateWrap from "@/components/FileOperateWrap";
import { ElMessage } from "element-plus";
import constants from "@/utils/constants";
import api from "@/api";
export default {
  name: "DepartFolderView",
  components: {
    BreadCrumb,
    BatterTableDataView,
    BatterPageDataView,
    IconFileList2,
    FileOperateHead,
    FileOperateWrap
  },
  data() {
    return {
      crumb: [{
        name: '离职人员文件'
      }],
      search: '',
      selectedFiles: [],
      folder: this.$route.params.folder
    };
  },
  computed: {
    ctxe() {
      return this.$store.state.contextMenu.event;
    },
    folderId() {
      return this.$route.params.folder;
    }
  },
  watch: {
    folderId(n) {
      this.folder = n;
      this.init();
    }
  },
  methods: {
    onSelectionChange(e) {
      this.selectedFiles = e.map(o => o.id);
    },
    onSearch(t) {
      this.search = t;
    },
    fileIcon(fileUrl) {
      return constants.getFileIcon(fileUrl);
    },
    onEnterFolder(f) {
      this.$router.push('/m/share/folder/depart/' + f.id);
    },
    fileExtName(f) {
      return constants.getFileType(f);
    },
    handleDrop(event) {
      event.preventDefault();
      ElMessage.error('此处无法上传文件');
    },
    init() {
      api.getFolderPath(this.folder).then(ret => {
        let path_crumb = [{
          name: '离职人员文件',
          link: '/m/share/folder/depart/0'
        }];
        for (let p of ret) {
          path_crumb.push({
            name: p.name,
            link: '/m/share/folder/depart/' + p.folderId
          });
        }
        if (path_crumb[path_crumb.length - 1]) {
          path_crumb[path_crumb.length - 1].link = null;
          this.crumb = path_crumb;
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};