const fileIcons = {
    zip: 'https://cdn.waityou.online/e7d397ad-1e91-bdcb-b5a5-e3bff2d2c97e.png',
    rar: 'https://cdn.waityou.online/e7d397ad-1e91-bdcb-b5a5-e3bff2d2c97e.png',
    xls: 'https://cdn.waityou.online/ca48246a-8276-d545-c43a-ed418e6bb00a.png',
    xlsx: 'https://cdn.waityou.online/ca48246a-8276-d545-c43a-ed418e6bb00a.png',
    jpg: 'https://cdn.waityou.online/b9145ff6-d12c-33a0-2b62-6ce1aeec4989.png',
    jpeg: 'https://cdn.waityou.online/b9145ff6-d12c-33a0-2b62-6ce1aeec4989.png',
    png: 'https://cdn.waityou.online/2ae43eba-1e6e-1752-a87b-e7a57e0f866d.svg',
    pdf: 'https://cdn.waityou.online/0ea77677-21ec-2104-53a8-dc8882918ce3.png',
    pdfx: 'https://cdn.waityou.online/0ea77677-21ec-2104-53a8-dc8882918ce3.png',
    ppt: 'https://cdn.waityou.online/756042a0-b930-c619-b296-b6d216bb10ae.png',
    pptx: 'https://cdn.waityou.online/756042a0-b930-c619-b296-b6d216bb10ae.png',
    doc: 'https://cdn.waityou.online/20dc4f1e-8cc2-3d65-9bac-18d53fa1f4e3.png',
    docx: 'https://cdn.waityou.online/20dc4f1e-8cc2-3d65-9bac-18d53fa1f4e3.png',
    folder:'https://cdn.waityou.online/0b56d07c-2e46-159a-2343-7d7e208de6fa.svg',
    txt:'https://cdn.waityou.online/fdde1aef-f660-4f64-5ea2-14c98893f5cb.svg',
    mp4:'https://cdn.waityou.online/8ae61cde-317f-dd0c-730e-4040ca040a6f.svg',
    mp3:'https://cdn.waityou.online/4ea6cf8e-9afa-977e-f840-85c00558f60f.svg',
    psd:'https://cdn.waityou.online/4a508dd3-2854-cc32-fd68-aa1f8777eec2.svg',
    file:'https://cdn.waityou.online/33b67df2-088a-918d-36e7-04753a201e5b.svg',
};
const fileTypes = {
    zip: '压缩包',
    rar: '压缩包',
    xls: '表格',
    xlsx: '表格',
    jpg: '图片',
    jpeg: '图片',
    png: '图片',
    pdf: '文档',
    pdfx: '文档',
    ppt: '演示文稿',
    pptx: '演示文稿',
    doc: '文档',
    docx: '文档',
    folder:'文件夹',
    txt:'文档',
    mp4:'视频',
    mp3:'音频',
    psd:'PS稿',
    file:'其他文件',
};
export default {
    getFileIcon: url => {
        if(!url){
            return fileIcons.file;
        }
        url = url.toLowerCase();
        for (let k of Object.keys(fileIcons)) {
            if (url.endsWith(k)) {
                return fileIcons[k]
            }
        }
        return fileIcons.file;
    },
    getFileType: url => {
        if(!url){
            return fileTypes.file;
        }
        for (let k of Object.keys(fileTypes)) {
            if (url.endsWith(k)) {
                return fileTypes[k]
            }
        }
        return fileTypes.file;
    },
    isImgFile: fileName => {
        if(!fileName){
            return false
        }
        return !!(fileName.endsWith('png') ||
            fileName.endsWith('jpg') ||
            fileName.endsWith('jpeg') ||
            fileName.endsWith('svg') ||
            fileName.endsWith('bmp') ||
            fileName.endsWith('git'));

    },
    isOfficeFile: fileName => {
        if(!fileName){
            return false
        }
        return !!(fileName.endsWith('txt') ||
            fileName.endsWith('doc') ||
            fileName.endsWith('docx') ||
            fileName.endsWith('ppt') ||
            fileName.endsWith('pptx') ||
            fileName.endsWith('xls') ||
            fileName.endsWith('xlsx') ||
            fileName.endsWith('pdf'));
    }
}