import api from "@/api";
export default {
  name: "CooEditorSelectView",
  props: {
    taskId: {
      type: Number,
      default: null
    },
    meetingId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      filterText: null,
      userTree: [],
      prop: {
        children: 'children',
        label: 'label'
      }
    };
  },
  watch: {
    filterText(v) {
      this.$refs.userTree.filter(v);
    }
  },
  methods: {
    onChecked(a, b) {
      this.$emit('checkedUsers', b.checkedKeys.filter(u => u < 0));
    },
    resetCheckbox() {
      this.$refs.userTree.setChecked(1, false, true);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.includes(value);
    },
    initCheckUsers() {
      if (this.taskId != null) {
        api.getCheckedUsers(this.taskId).then(list => {
          this.$refs.userTree.setCheckedKeys(list);
        });
      } else if (this.meetingId != null) {
        api.getMeetingCheckedUsers(this.meetingId).then(list => {
          this.$refs.userTree.setCheckedKeys(list);
        });
      }
    },
    init() {
      api.getUserTree().then(ret => {
        this.userTree = [ret];
        if (this.taskId != null || this.meetingId != null) {
          this.initCheckUsers();
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};