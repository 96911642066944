import "core-js/modules/es.array.push.js";
import EditableFileIcon2 from "@/components/EditableFileIcon2";
import api from "@/api";
import uuid from "@/utils/uuid";
import FileOperateWrap from "@/components/FileOperateWrap";
export default {
  name: "IconFileList2",
  components: {
    FileOperateWrap,
    EditableFileIcon2
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    firstLayer: {
      type: Boolean,
      default: false
    },
    showShareIcon: {
      type: Boolean,
      default: false
    },
    fenguanNames: {
      type: Array,
      default: () => ['其他处室', '分管处室']
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      ek: uuid.uuid(),
      selectedFile: null,
      checkboxes: {},
      groups: []
    };
  },
  methods: {
    onRefresh() {
      let thiz = this;
      setTimeout(() => {
        thiz.$emit('refresh');
      }, 200);
    },
    onCheckBoxChange() {
      let selected = [];
      for (let k of Object.keys(this.checkboxes)) {
        if (this.checkboxes[k]) {
          selected.push({
            id: k
          });
        }
      }
      this.$emit('onSelectionChange', selected);
    },
    onDragStart(e, f) {
      e.dataTransfer.setData('text/plain', 'DRAG_' + f.id);
    },
    onDrop(e, f) {
      e.preventDefault();
      let dragData = e.dataTransfer.getData('text/plain');
      if (dragData != null && dragData.startsWith('DRAG_')) {
        //把文件放进文件夹操作
        api.moveFile({
          fileId: dragData.substring(5),
          folderId: f.id
        }).then(() => {
          this.$emit('refresh');
        });
      }
    },
    init() {
      let responsibilities = [];
      let other = [];
      this.groups = [];
      for (let f of this.data) {
        if (f.responsibility) {
          responsibilities.push(f);
        } else {
          other.push(f);
        }
      }
      if (responsibilities.length > 0) {
        this.groups.push(responsibilities);
      }
      this.groups.push(other);
    }
  },
  mounted() {
    this.init();
  }
};