import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2c8abb2c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "FilePreviewerContainer"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_DialogCustomView, {
    "visible-control": $data.previewVisible,
    title: "文件预览",
    width: "600px",
    onClose: _cache[0] || (_cache[0] = $event => $data.previewVisible = false)
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      class: "bothCenter",
      style: {
        "min-height": "200px"
      }
    }, {
      default: _withCtx(() => [$data.previewVisible ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $data.previewUrl,
        style: {
          "max-width": "600px"
        }
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }, 8, ["visible-control"])]);
}