import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-516f2b8a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "TaskListManagerViewContainer"
};
const _hoisted_2 = {
  key: 0,
  class: "miniFont brd4",
  style: {
    "color": "white",
    "background": "#B6B5B5",
    "padding": "2px 6px"
  }
};
const _hoisted_3 = {
  key: 1,
  class: "miniFont brd4",
  style: {
    "color": "white",
    "background": "#27BF94",
    "padding": "2px 6px"
  }
};
const _hoisted_4 = {
  key: 2,
  class: "miniFont brd4",
  style: {
    "color": "white",
    "background": "#1E427A",
    "padding": "2px 6px"
  }
};
const _hoisted_5 = {
  key: 3,
  class: "miniFont brd4",
  style: {
    "color": "white",
    "background": "#B6B5B5",
    "padding": "2px 6px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 10,
    onAddMore: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/m/meeting/create')),
    onOnDetailData: $options.onDetailData,
    onOnEditData: $options.onEditData,
    ref: "meetingTable"
  }, {
    meetingType: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    state: _withCtx(data => [data.rowData == 'INIT' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "未开始")) : _createCommentVNode("", true), data.rowData == 'MEETING' ? (_openBlock(), _createElementBlock("span", _hoisted_3, "进行中")) : _createCommentVNode("", true), data.rowData == 'COMPLETE' ? (_openBlock(), _createElementBlock("span", _hoisted_4, "已完成")) : _createCommentVNode("", true), data.rowData == 'CANCEL' ? (_openBlock(), _createElementBlock("span", _hoisted_5, "已取消")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["onOnDetailData", "onOnEditData"])])], 64);
}