import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-097b00bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "PdfViewerContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_vue_pdf_app = _resolveComponent("vue-pdf-app");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), $data.pdfUrl ? (_openBlock(), _createBlock(_component_vue_pdf_app, {
    key: 0,
    style: {
      "height": "80vh"
    },
    theme: "light",
    pdf: $data.pdfUrl
  }, null, 8, ["pdf"])) : _createCommentVNode("", true)]);
}