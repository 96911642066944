import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "MyCatalogView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      addMoreVisible: false,
      editSheetVisible: false,
      crumb: [{
        name: '数据目录'
      }, {
        name: '数据上报'
      }],
      uploadSubmit: {},
      rawSheet: []
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};