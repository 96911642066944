import * as echarts from 'echarts';
import uuid from "@/utils/uuid";
export default {
  name: "BarChart",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    data() {
      this.renderChart();
    }
  },
  data() {
    return {
      chartId: uuid.uuid()
    };
  },
  methods: {
    renderChart() {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: e => {
            return this.data.data[e.dataIndex].hint;
          }
        },
        grid: {
          top: '10%',
          left: '3%',
          right: '3%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.data.data.map(d => d.name),
          axisLabel: {
            interval: 0,
            // 显示所有标签
            rotate: 30
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: this.data.data.map(d => d.value),
          type: 'bar',
          itemStyle: {
            color: '#387DFF'
          },
          barWidth: '20px'
        }]
      };
      let bar = document.getElementById(this.chartId);
      let exist = echarts.getInstanceByDom(bar);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(bar);
      chart.setOption(option);
    }
  },
  mounted() {
    this.renderChart();
  }
};