import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-21748605"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "CatalogDetailViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$catalog, _$data$catalog$catalo;
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_CatalogInfoTable = _resolveComponent("CatalogInfoTable");
  const _component_CatalogVersionTable = _resolveComponent("CatalogVersionTable");
  const _component_CatalogDataInfoTable = _resolveComponent("CatalogDataInfoTable");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_CatalogInfoTable, {
    data: $data.catalog.catalog,
    owner: $data.catalog.owner,
    isOwner: $data.catalog.isOwner,
    onRefresh: $options.init
  }, null, 8, ["data", "owner", "isOwner", "onRefresh"]), _createVNode(_component_CatalogVersionTable, {
    class: "mt16",
    data: $data.catalog.versions,
    owner: $data.catalog.isOwner,
    onRefresh: $options.init
  }, null, 8, ["data", "owner", "onRefresh"]), ((_$data$catalog = $data.catalog) === null || _$data$catalog === void 0 ? void 0 : (_$data$catalog$catalo = _$data$catalog.catalog) === null || _$data$catalog$catalo === void 0 ? void 0 : _$data$catalog$catalo.fileType) == 'EXCEL' ? (_openBlock(), _createBlock(_component_CatalogDataInfoTable, {
    key: 0,
    class: "mt16",
    data: $data.catalog.catalog,
    fields: $data.catalog.fields,
    onRefresh: $options.init
  }, null, 8, ["data", "fields", "onRefresh"])) : _createCommentVNode("", true)])], 64);
}