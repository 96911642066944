import api from "@/api";
import { DocumentEditor } from "@onlyoffice/document-editor-vue";
import BreadCrumb from "@/components/BreadCrumb";
import { ElMessage } from "element-plus";
export default {
  name: "FileEditorView",
  components: {
    DocumentEditor,
    BreadCrumb
  },
  data() {
    return {
      config: null,
      fileKey: this.$route.params.fileKey,
      crumb: [{
        name: '文件编辑器'
      }],
      loading: null,
      editorInstance: null
    };
  },
  methods: {
    onRequestHistoryClose() {
      document.location.reload();
    },
    onRequestHistory() {
      api.getFileEditHistory({
        fileKey: this.fileKey
      }).then(ret => {
        this.editorInstance.refreshHistory(ret);
      });
    },
    onRequestHistoryData(e) {
      api.getFileEditHistoryVersion({
        fileKey: this.fileKey,
        version: e.data
      }).then(ret => {
        this.editorInstance.setHistoryData(ret);
      });
    },
    fullScreen() {
      var iframe = document.getElementsByName("frameEditor")[0];
      console.log('iframe', iframe);
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        // IE/Edge
        iframe.msRequestFullscreen();
      }
    },
    init() {
      // this.loading = ElLoading.service({
      //   lock: true,
      //   text: '正在加载请稍后,首次打开需加载资源将等待更多时间...',
      //   background: 'rgba(0, 0, 0, 0.7)',
      // })
      ElMessage.success('正在加载请稍后,首次打开需加载资源将等待更多时间...');
      api.getEditorInfoByFileKey(this.fileKey).then(ret => {
        this.config = ret;
        if (!ret.editable) {
          ElMessage.info('您处于只读模式,无法编辑');
        }
      });
    },
    onAppReady(e) {
      this.editorInstance = e;
    },
    onDocumentReady(e) {
      console.log("Document is loaded", e);
      if (this.loading) {
        this.loading.close();
      }
    },
    onDocumentError(e) {
      console.log('onDocumentError', e);
      if (this.loading) {
        this.loading.close();
      }
    },
    onDocumentInfo(e) {
      console.log('onDocumentInfo', e);
      if (this.loading) {
        this.loading.close();
      }
    },
    onDocumentWarning(e) {
      console.log('onDocumentWarning', e);
      if (this.loading) {
        this.loading.close();
      }
    }
  },
  mounted() {
    this.init();
  }
};