import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2d5931aa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "TaskListManagerViewContainer"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 0,
  style: {
    "color": "red"
  }
};
const _hoisted_4 = {
  key: 1,
  style: {
    "color": "green"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 9,
    onAddMore: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/m/task/create')),
    onOnDetailData: $options.onDetailData,
    onOnEditData: $options.onEditData,
    ref: "taskTable"
  }, {
    repeatType: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    state: _withCtx(data => [data.data.taskType == 'DATA_REPORT' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.mapping[data.rowData]), 1)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [data.rowData != 'SHARE' && data.rowData != 'STORED' ? (_openBlock(), _createElementBlock("span", _hoisted_3, "执行中")) : (_openBlock(), _createElementBlock("span", _hoisted_4, "已完成"))], 64))]),
    _: 1
  }, 8, ["onOnDetailData", "onOnEditData"])])], 64);
}