export default {
  name: "ContextMenu",
  methods: {
    fireEvent(k) {
      this.$store.commit('dispatchContextMenuKey', k);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};