import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1bbf9f0a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "color": "#121F2C"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_Close = _resolveComponent("Close");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.dialogVisible = $event),
    width: $props.width,
    onClose: $options.onDialogClose,
    "append-to-body": true,
    "show-close": false
  }, {
    header: _withCtx(() => [_createVNode(_component_el_row, {
      style: {
        "background": "#F5F6F7",
        "border-radius": "8px 8px 0 0"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 20,
        class: "leftCenter opd12 ft14"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($props.title), 1)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 4,
        style: {
          "align-self": "center",
          "text-align": "right",
          "padding": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          style: {
            "cursor": "pointer"
          },
          onClick: _cache[0] || (_cache[0] = $event => $data.dialogVisible = false)
        }, {
          default: _withCtx(() => [_createVNode(_component_Close)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "dialog", {}, undefined, true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["modelValue", "width", "onClose"]);
}