import api from "@/api";
export default {
  name: "VersionEditHistoryCard",
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    deleteFile() {
      api.deleteEditHistory(this.data.id).then(() => {
        this.$emit('HistoryChange');
      });
    },
    downloadFile() {
      window.open(this.data.versionFileUrl, '_blank');
    },
    read() {},
    init() {}
  },
  mounted() {
    this.init();
  }
};