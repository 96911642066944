import api from "@/api";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "ShareFileInfo",
  components: {
    ButtonDialog
  },
  props: {
    fileId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      detail: [],
      feedbacks: [],
      feedId: null
    };
  },
  methods: {
    onReply(feedback) {
      this.feedId = feedback.id;
      this.$refs.reply.openDialog();
    },
    init() {
      api.getFileMoreInfo(this.fileId).then(ret => {
        this.detail = ret;
      });
      api.getFeedbackList(this.fileId).then(ret => {
        this.feedbacks = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};