import "core-js/modules/es.array.push.js";
import BreadCrumb from "@/components/BreadCrumb";
import api from "@/api";
import CatalogSelectView from "@/views/task/CatalogSelectView";
import CooEditorSelectView from "@/views/task/CooEditorSelectView";
import AttachmentFiles from "@/components/AttachmentFiles";
import OssUploader from "@/components/OssUploader";
import EditableFileIcon from "@/components/EditableFileIcon";
export default {
  name: "CreateTaskView",
  components: {
    CatalogSelectView,
    BreadCrumb,
    CooEditorSelectView,
    AttachmentFiles,
    OssUploader,
    EditableFileIcon
  },
  data() {
    return {
      taskId: this.$route.query.taskId,
      task: {
        taskType: 4,
        description: null,
        allDay: false,
        checkedUsers: [],
        dingRemindType: 0,
        smsRemindType: 0,
        dingRemindTime: 0,
        smsRemindTime: 0,
        attachments: []
      },
      crumb: [{
        name: '任务管理',
        link: '/m/task/list'
      }, {
        name: '新建任务'
      }],
      taskTypes: [],
      remindTimes: [],
      focusOn: 0,
      repeatTypes: {}
    };
  },
  methods: {
    onRemove(idx) {
      this.task.attachments.splice(idx, 1);
    },
    updateAttachment(file) {
      this.task.attachments.push(file);
    },
    submit() {
      if (this.taskId) {
        this.task.taskId = this.taskId;
        api.updateCatalogTask(this.task).then(() => {
          this.$router.push('/m/task/detail/' + this.taskId);
        });
      } else {
        api.createCatalogTask(this.task).then(ret => {
          this.$router.push('/m/task/detail/' + ret.id);
        });
      }
    },
    onCheckedUsers(ids) {
      console.log('onCheckedUsers', ids);
      this.task.checkedUsers = ids;
    },
    onSelectedVersion(versionId) {
      api.getVersionDownloadInfo(versionId).then(ret => {
        this.task.versionId = versionId;
        this.task.versionName = ret.fileName;
      });
    },
    init() {
      api.getCreateTaskInitData().then(ret => {
        this.remindTimes = ret.remindTimes;
        this.taskTypes = ret.taskTypes;
        this.taskType = ret.defaultTaskType;
        this.repeatTypes = ret.repeatTypes;
        this.task.repeat = ret.repeatTypes[0].value;
      });
    }
  },
  mounted() {
    this.init();
    if (this.taskId != null) {
      api.getTaskEditInfo(this.taskId).then(ret => {
        this.task = ret;
      });
    }
  }
};