import DialogView from "@/components/DialogView";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "EnumValueListView",
  components: {
    DialogView,
    BatterTableDataView
  },
  data() {
    return {
      type: this.$route.params.type,
      title: '-',
      createVisible: false,
      initQuery: {
        type: this.$route.params.type
      },
      switches: {}
    };
  },
  watch: {
    '$route.params.type'(newV) {
      this.type = newV;
      this.initQuery.type = newV;
      this.$refs.enumValueTable.fetchData();
    }
  },
  methods: {
    createEnumValue(data) {
      data.type = this.type;
      api.createEnumValue(data).then(() => {
        this.createVisible = false;
        this.$refs.enumValueTable.fetchData();
      });
    },
    updateEnableState(dataId, value) {
      api.updateEnumValueEnableState(dataId, value).then(() => {
        this.switches[dataId] = value;
        this.$refs.enumValueTable.fetchData();
      });
    },
    onFetchData(data) {
      this.title = data.title;
      let list = data['list'];
      for (let l of list) {
        this.switches[l['id']] = l['enable'];
      }
    }
  }
};