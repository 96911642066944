import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$selectedFile, _$props$selectedFile2, _$props$selectedFile3, _$props$selectedFile4, _$props$selectedFile5, _$props$selectedFile6, _$props$selectedFile7, _$props$selectedFile8, _$props$selectedFile9, _$props$selectedFile10, _$props$selectedFile11, _$props$selectedFile12;
  const _component_ButtonDialog = _resolveComponent("ButtonDialog");
  const _component_el_row = _resolveComponent("el-row");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  const _component_ShareFileInfo = _resolveComponent("ShareFileInfo");
  const _component_el_drawer = _resolveComponent("el-drawer");
  const _component_LimitAccessEditor = _resolveComponent("LimitAccessEditor");
  return _openBlock(), _createElementBlock("div", {
    onDblclick: _cache[9] || (_cache[9] = (...args) => $options.onDblclick && $options.onDblclick(...args))
  }, [_renderSlot(_ctx.$slots, "default", {
    showContext: $options.showContextMenu
  }), _createVNode(_component_ButtonDialog, {
    "btn-id": 1,
    "bind-id": (_$props$selectedFile = $props.selectedFile) === null || _$props$selectedFile === void 0 ? void 0 : _$props$selectedFile.id,
    onFresh: _cache[0] || (_cache[0] = $event => _ctx.$emit('refresh')),
    "slot-btn": "",
    "init-data": {
      fileId: (_$props$selectedFile2 = $props.selectedFile) === null || _$props$selectedFile2 === void 0 ? void 0 : _$props$selectedFile2.id
    },
    ref: "rename"
  }, null, 8, ["bind-id", "init-data"]), _createVNode(_component_ButtonDialog, {
    "btn-id": 2,
    "bind-id": (_$props$selectedFile3 = $props.selectedFile) === null || _$props$selectedFile3 === void 0 ? void 0 : _$props$selectedFile3.id,
    onFresh: _cache[1] || (_cache[1] = $event => _ctx.$emit('refresh')),
    "slot-btn": "",
    "init-data": {
      fileId: (_$props$selectedFile4 = $props.selectedFile) === null || _$props$selectedFile4 === void 0 ? void 0 : _$props$selectedFile4.id
    },
    ref: "encrypt"
  }, null, 8, ["bind-id", "init-data"]), _createVNode(_component_ButtonDialog, {
    "btn-id": 3,
    "bind-id": (_$props$selectedFile5 = $props.selectedFile) === null || _$props$selectedFile5 === void 0 ? void 0 : _$props$selectedFile5.id,
    onFresh: _cache[2] || (_cache[2] = $event => _ctx.$emit('refresh')),
    "slot-btn": "",
    "init-data": {
      fileId: (_$props$selectedFile6 = $props.selectedFile) === null || _$props$selectedFile6 === void 0 ? void 0 : _$props$selectedFile6.id
    },
    ref: "decrypt"
  }, null, 8, ["bind-id", "init-data"]), _createVNode(_component_ButtonDialog, {
    "btn-id": 4,
    "bind-id": (_$props$selectedFile7 = $props.selectedFile) === null || _$props$selectedFile7 === void 0 ? void 0 : _$props$selectedFile7.id,
    onFresh: _cache[3] || (_cache[3] = $event => _ctx.$emit('refresh')),
    "slot-btn": "",
    "init-data": {
      fileId: (_$props$selectedFile8 = $props.selectedFile) === null || _$props$selectedFile8 === void 0 ? void 0 : _$props$selectedFile8.id
    },
    ref: "feedback"
  }, null, 8, ["bind-id", "init-data"]), _createVNode(_component_ButtonDialog, {
    "btn-id": 6,
    "bind-id": (_$props$selectedFile9 = $props.selectedFile) === null || _$props$selectedFile9 === void 0 ? void 0 : _$props$selectedFile9.id,
    onFresh: _cache[4] || (_cache[4] = $event => _ctx.$emit('refresh')),
    "slot-btn": "",
    "init-data": {
      fileId: (_$props$selectedFile10 = $props.selectedFile) === null || _$props$selectedFile10 === void 0 ? void 0 : _$props$selectedFile10.id
    },
    ref: "copyFile"
  }, null, 8, ["bind-id", "init-data"]), _createVNode(_component_ButtonDialog, {
    "btn-id": 7,
    "bind-id": (_$props$selectedFile11 = $props.selectedFile) === null || _$props$selectedFile11 === void 0 ? void 0 : _$props$selectedFile11.id,
    onFresh: _cache[5] || (_cache[5] = $event => _ctx.$emit('refresh')),
    "slot-btn": "",
    "init-data": {
      fileId: (_$props$selectedFile12 = $props.selectedFile) === null || _$props$selectedFile12 === void 0 ? void 0 : _$props$selectedFile12.id
    },
    ref: "moveFile"
  }, null, 8, ["bind-id", "init-data"]), _createVNode(_component_DialogCustomView, {
    "visible-control": $data.previewVisible,
    title: "文件预览",
    width: "600px",
    onClose: _cache[6] || (_cache[6] = $event => $data.previewVisible = false)
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      class: "bothCenter",
      style: {
        "min-height": "200px"
      }
    }, {
      default: _withCtx(() => [$data.previewVisible ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $data.previewUrl,
        style: {
          "max-width": "600px"
        }
      }, null, 8, _hoisted_1)) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }, 8, ["visible-control"]), _createVNode(_component_el_drawer, {
    "with-header": false,
    "append-to-body": true,
    "model-value": $data.detailVisible,
    size: "500px",
    onClose: _cache[7] || (_cache[7] = $event => $data.detailVisible = false)
  }, {
    default: _withCtx(() => [$data.detailVisible ? (_openBlock(), _createBlock(_component_ShareFileInfo, {
      key: 0,
      fileId: $props.selectedFile.id
    }, null, 8, ["fileId"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["model-value"]), _createVNode(_component_el_drawer, {
    "with-header": false,
    "append-to-body": true,
    "model-value": $data.setLimitVisible,
    size: "500px",
    onClose: _cache[8] || (_cache[8] = $event => $data.setLimitVisible = false)
  }, {
    default: _withCtx(() => [$data.setLimitVisible ? (_openBlock(), _createBlock(_component_LimitAccessEditor, {
      key: 0,
      selected: $data.limitUserIds,
      fileId: $props.selectedFile.id,
      onCheckedUsers: $options.updateLimitAccessUser
    }, null, 8, ["selected", "fileId", "onCheckedUsers"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["model-value"])], 32);
}