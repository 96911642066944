import DialogCustomView from "@/components/DialogCustomView";
import ButtonDialog from "@/components/ButtonDialog";
import ShareFileInfo from "@/components/ShareFileInfo";
import api from "@/api";
import { ElMessage } from "element-plus";
import uuid from "@/utils/uuid";
import { loadObjectIfNotExist } from "@/store/store.helper";
import LimitAccessEditor from "@/components/file/LimitAccessEditor";
export default {
  name: "FileOperateWrap",
  components: {
    LimitAccessEditor,
    DialogCustomView,
    ButtonDialog,
    ShareFileInfo
  },
  emits: ['refresh', 'enterFolder'],
  props: {
    selectedFile: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    ctxe() {
      return this.$store.state.contextMenu.event;
    }
  },
  watch: {
    ctxe(n) {
      if (!n || !n.startsWith(this.ek)) {
        return;
      }
      if (n.endsWith('delete')) {
        this.onDeleteFile();
      } else if (n.endsWith('rename')) {
        this.onReNameFile();
      } else if (n.endsWith('cancel_share')) {
        this.onShareFile(false);
      } else if (n.endsWith('share')) {
        this.onShareFile(true);
      } else if (n.endsWith('download')) {
        this.onDownloadFile(true);
      } else if (n.endsWith('encrypt')) {
        this.onEncryptFile(true);
      } else if (n.endsWith('decrypt')) {
        this.onDecryptFile(true);
      } else if (n.endsWith('collect')) {
        this.onCollectFile(true);
      } else if (n.endsWith('more_info')) {
        this.onMoreInfo(true);
      } else if (n.endsWith('feedback')) {
        this.onFeedback(true);
      } else if (n.endsWith('copy')) {
        this.onCopyFile(true);
      } else if (n.endsWith('move')) {
        this.onMoveFile(true);
      } else if (n.endsWith('set_limit_r')) {
        this.onSetLimitR(true);
      } else if (n.endsWith('set_limit_w')) {
        this.onSetLimitW(true);
      }
    }
  },
  data() {
    return {
      previewVisible: false,
      detailVisible: false,
      setLimitVisible: false,
      setLimitRead: false,
      limitUserIds: [],
      previewUrl: null,
      ek: uuid.uuid()
    };
  },
  methods: {
    updateLimitAccessUser(ls) {
      api.updateLimitAccessUser({
        folder: this.selectedFile.id,
        userIds: ls.map(l => l * -1),
        read: this.setLimitRead
      }).then(() => {
        ElMessage.success('更新成功');
        this.setLimitVisible = false;
      });
    },
    onDblclick() {
      if (this.selectedFile.fileType == "FOLDER") {
        this.$emit('enterFolder', this.selectedFile);
      } else {
        api.getFilePreviewLink({
          fileId: this.selectedFile.id
        }).then(ret => {
          if (ret.type == 'image') {
            this.previewUrl = ret.url;
            this.previewVisible = true;
          } else if (ret.type == 'url') {
            window.open(ret.url, '_blank');
          }
        });
      }
    },
    onSetLimitR() {
      api.getAccessLimit(this.selectedFile.id).then(ret => {
        this.setLimitVisible = true;
        this.setLimitRead = true;
        this.limitUserIds = ret.readUserList.map(p => p * -1);
      });
    },
    onSetLimitW() {
      api.getAccessLimit(this.selectedFile.id).then(ret => {
        this.setLimitVisible = true;
        this.setLimitRead = false;
        this.limitUserIds = ret.writeUserList.map(p => p * -1);
      });
    },
    onMoveFile() {
      this.$refs.moveFile.openDialog();
    },
    onCopyFile() {
      this.$refs.copyFile.openDialog();
    },
    onFeedback() {
      this.$refs.feedback.openDialog();
    },
    onMoreInfo() {
      this.detailVisible = true;
    },
    onCollectFile() {
      api.collectFile(this.selectedFile.id).then(() => {
        ElMessage.success('收藏成功');
      });
    },
    onEncryptFile() {
      this.$refs.encrypt.openDialog();
    },
    onDecryptFile() {
      this.$refs.decrypt.openDialog();
    },
    onDownloadFile() {
      api.getFileDownloadLink({
        fileId: this.selectedFile.id
      }).then(ret => {
        if (ret) {
          window.open(ret.url, '_blank');
        }
      });
    },
    onShareFile(s) {
      api.setFileShareState({
        fileId: this.selectedFile.id,
        state: s ? 'SHARING' : 'NO'
      }).then(() => {
        this.$emit('refresh');
      });
    },
    onDeleteFile() {
      api.deleteFile(this.selectedFile.id).then(() => {
        this.$emit('refresh');
      });
    },
    onReNameFile() {
      this.$refs.rename.openDialog();
    },
    showContextMenu(event) {
      // 阻止默认右键菜单
      event.preventDefault();
      if (!this.selectedFile.operates || this.selectedFile.operates.length < 1) {
        return;
      }
      let optMap = this.$store.getters.fetchObjectData('optMap');
      let data = {
        style: {
          top: event.clientY + 'px',
          left: event.clientX + 'px'
        },
        menus: this.selectedFile.operates.map(op => {
          return {
            name: optMap[op],
            key: this.ek + op
          };
        })
      };
      this.$store.commit('showContextMenu', data);
    },
    hideContextMenu() {
      this.$store.commit('hideContextMenu');
    },
    init() {}
  },
  mounted() {
    loadObjectIfNotExist(this, 'optMap', 'getFileOperates');
    this.init();
  }
};