import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from "@/views/HomeView";
import UserRoleListView from "@/views/user/UserRoleListView";
import OrganizationSettingView from "@/views/user/OrganizationSettingView";
import UserListView from "@/views/user/UserListView";
import EnumValueListView from "@/views/system/EnumValueListView";
import ProactivelyReportingView from "@/views/catalog/ProactivelyReportingView";
import TestView from "@/views/TestView";
import CatalogDetailView from "@/views/catalog/CatalogDetailView";
import ManagerDashboard from "@/views/dashboard/ManagerDashboard";
import FileEditorView from "@/views/FileEditorView";
import MyCatalogView from "@/views/catalog/MyCatalogView";
import CatalogCheckoutView from "@/views/catalog/CatalogCheckoutView";
import CreateTaskView from "@/views/task/CreateTaskView";
import TaskListView from "@/views/task/TaskListView";
import CatalogTaskDetailView from "@/views/task/CatalogTaskDetailView";
import MeetingListView from "@/views/meeting/MeetingListView";
import MeetingCreateView from "@/views/meeting/MeetingCreateView";
import MeetingDetailView from "@/views/meeting/MeetingDetailView";
import OrgShareFolderView from "@/views/folder/OrgShareFolderView";
import BattalionShareFolderView from "@/views/folder/BattalionShareFolderView";
import PersonalShareFolderView from "@/views/folder/PersonalShareFolderView";
import UserCollectionFolderView from "@/views/folder/UserCollectionFolderView";
import ShareFileDashboardView from "@/views/catalog/ShareFileDashboardView";
// import ShareFileManagerDashboardView from "@/views/dashboard/ShareFileManagerDashboardView";
import FileSearchView from "@/views/folder/FileSearchView";
import ShareFileListDashboardView from "@/views/catalog/ShareFileListDashboardView";
import DepartmentFolderView from "@/views/folder/DepartmentFolderView";
import BattalionFolderView from "@/views/folder/BattalionFolderView";
import PdfViewer from "@/views/PdfViewer";
import RecycleFolderView from "@/views/folder/RecycleFolderView";
import DepartFolderView from "@/views/folder/DepartFolderView";
const routes = [
    {
        path: "/m",
        name: "home",
        component: HomeView,
        redirect: "/m/dashboard",
        children: [
            {
                path: "/m/dashboard",
                component: ManagerDashboard
            },{
                path: "/m/dashboard/console",
                component: ManagerDashboard
            },{
                path: '/m/role/list',
                component: UserRoleListView
            },{
                path: '/m/org/list',
                component: OrganizationSettingView
            },{
                path: '/m/user/list',
                component: UserListView
            },{
                path: '/m/enum/list/:type',
                component: EnumValueListView
            },{
                path: '/m/proactively/reporting',
                component: ProactivelyReportingView
            },{
                path: '/m/catalog/detail/:catalogId',
                component: CatalogDetailView
            },{
                path: '/m/office/editor/:fileKey',
                component: FileEditorView
            },{
                path: '/m/office/pdf/:fileKey',
                component: PdfViewer
            },{
                path: '/m/my/data',
                component: MyCatalogView
            },{
                path: '/m/catalog/checkout',
                component: CatalogCheckoutView
            },{
                path: '/m/task/create',
                component: CreateTaskView
            },{
                path: '/m/task/list',
                component: TaskListView
            },{
                path: '/m/task/detail/:taskId',
                component: CatalogTaskDetailView
            },{
                path: '/m/meeting/list',
                component: MeetingListView
            },{
                path: '/m/meeting/create',
                component: MeetingCreateView
            },{
                path: '/m/meeting/detail/:meetingId',
                component: MeetingDetailView
            },{
                path: '/m/share/folder/org/:folder',
                component: OrgShareFolderView
            },{
                path: '/m/share/folder/battalion/:folder',
                component: BattalionShareFolderView
            },{
                path: '/m/share/folder/personal/:folder',
                component: PersonalShareFolderView
            },{
                path: '/m/share/folder/collection',
                component: UserCollectionFolderView
            },{
                path: '/m/share/folder/search',
                component: FileSearchView
            },{
                path: '/m/share/department/folders',
                component: DepartmentFolderView
            },{
                path: '/m/share/battalion/folders',
                component: BattalionFolderView
            },{
                path: '/m/share/folder/recycle',
                component: RecycleFolderView
            },{
                path: '/m/share/folder/depart/:folder',
                component: DepartFolderView
            },
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            auth: false
        }
    },
    {
        path: '/test',
        name: 'test',
        component: TestView,
        meta: {
            auth: false
        }
    },
    {
        path: '/',
        name: 'Index',
        component: ShareFileDashboardView,
        meta: {
            auth: false
        }
    },
    {
        path: '/l',
        name: 'ListIndex',
        component: ShareFileListDashboardView,
        meta: {
            auth: false
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
