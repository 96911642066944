import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "IconFileList2Container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_EditableFileIcon2 = _resolveComponent("EditableFileIcon2");
  const _component_FileOperateWrap = _resolveComponent("FileOperateWrap");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.groups, (group, groupIndex) => {
    return _openBlock(), _createBlock(_component_el_row, {
      key: `group_${groupIndex}`
    }, {
      default: _withCtx(() => [$data.groups.length > 1 ? (_openBlock(), _createBlock(_component_el_col, {
        key: 0,
        span: 24,
        class: "mt12"
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(groupIndex > 0 ? $props.fenguanNames[0] : $props.fenguanNames[1]), 1)]),
        _: 2
      }, 1024)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, f => {
        return _openBlock(), _createBlock(_component_el_col, {
          key: `file_${f.id}`,
          span: 6,
          class: "leftCenter fileItem pt16 brd4 noSelect"
        }, {
          default: _withCtx(() => [_createVNode(_component_FileOperateWrap, {
            onRefresh: $options.onRefresh,
            "selected-file": f,
            onEnterFolder: _cache[0] || (_cache[0] = e => _ctx.$emit('enterFolder', e))
          }, {
            default: _withCtx(fow => [_createVNode(_component_EditableFileIcon2, {
              file: f,
              onDragstart: e => $options.onDragStart(e, f),
              onDrop: _withModifiers(e => $options.onDrop(e, f), ["prevent", "stop"]),
              share: f.fileType != 'FOLDER' && f.shareState == 'SHARING' && $props.showShareIcon,
              "no-delete-btn": "",
              onShowContext: fow.showContext
            }, null, 8, ["file", "onDragstart", "onDrop", "share", "onShowContext"])]),
            _: 2
          }, 1032, ["onRefresh", "selected-file"])]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 2
    }, 1024);
  }), 128))]);
}