import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-06dede5c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "RepoCategorySettingViewContainer"
};
const _hoisted_2 = {
  key: 0,
  src: "https://cdn.waityou.online/0fb66ab8-ce74-28c9-8634-e2b32966908e.svg",
  class: "sqr12 ml8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_popconfirm = _resolveComponent("el-popconfirm");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    style: {
      "width": "100%"
    },
    class: "pd16"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_input, {
      "prefix-icon": "Search",
      modelValue: $data.filterText,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.filterText = $event),
      placeholder: "组织搜索"
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    },
    class: "pd16"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tree, {
      style: {
        "width": "100%"
      },
      data: $data.orgTree,
      "node-key": "id",
      "default-expand-all": "",
      "allow-drop": $options.allowDropCheck,
      draggable: "",
      ref: "treeView",
      "filter-node-method": $options.filterNode,
      "expand-on-click-node": false,
      onNodeDrop: $options.handleDrop
    }, {
      default: _withCtx(({
        node,
        data
      }) => [_createVNode(_component_el_row, {
        class: "bothCenter",
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 12,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(node.label), 1), data.data.dingOrgId ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true)]),
          _: 2
        }, 1024), _createVNode(_component_el_col, {
          span: 12,
          class: "rightCenter"
        }, {
          default: _withCtx(() => [data.children == null || data.children.length < 1 ? (_openBlock(), _createBlock(_component_el_popconfirm, {
            key: 0,
            title: "确定删除吗",
            onConfirm: $event => $options.deleteOrg(data)
          }, {
            reference: _withCtx(() => [_createVNode(_component_el_button, {
              type: "danger",
              size: "small"
            }, {
              default: _withCtx(() => [_createTextVNode("删除")]),
              _: 1
            })]),
            _: 2
          }, 1032, ["onConfirm"])) : _createCommentVNode("", true), _createVNode(_component_el_button, {
            type: "warning",
            size: "small",
            onClick: $event => $options.editOrg(data)
          }, {
            default: _withCtx(() => [_createTextVNode("编辑组织")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            type: "primary",
            size: "small",
            onClick: $event => $options.createSub(data)
          }, {
            default: _withCtx(() => [_createTextVNode("新增子组织")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)]),
      _: 1
    }, 8, ["data", "allow-drop", "filter-node-method", "onNodeDrop"])]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "visible-control": $data.createVisible,
    onClose: _cache[1] || (_cache[1] = $event => $data.createVisible = false),
    onSubmit: $options.createOrg,
    "data-id": 2,
    "bind-id": $data.superId
  }, null, 8, ["visible-control", "onSubmit", "bind-id"]), _createVNode(_component_DialogView, {
    "visible-control": $data.editVisible,
    onClose: _cache[2] || (_cache[2] = $event => $data.editVisible = false),
    onSubmit: $options.updateOrg,
    "data-id": 1,
    "bind-id": $data.editId
  }, null, 8, ["visible-control", "onSubmit", "bind-id"])], 64);
}