import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c84c878a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "CatalogSelectViewContainer"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  const _component_CatalogUploadProcess = _resolveComponent("CatalogUploadProcess");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 8,
    ref: "fileTable",
    onAddMore: $options.onAddMore,
    onOnDetailData: $options.onDetailData
  }, {
    "operates-handle": _withCtx(data => [_createElementVNode("span", {
      style: {
        "cursor": "pointer",
        "color": "#1388ff"
      },
      onClick: $event => $options.selectVersion(data.rowId)
    }, "选择", 8, _hoisted_2)]),
    _: 1
  }, 8, ["onAddMore", "onOnDetailData"])]), _createVNode(_component_DialogCustomView, {
    title: "选择版本",
    onClose: _cache[2] || (_cache[2] = $event => $data.versionVisible = false),
    "visible-control": $data.versionVisible
  }, {
    default: _withCtx(() => [_createVNode(_component_el_radio_group, {
      modelValue: $data.versionId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.versionId = $event),
      style: {
        "display": "flex",
        "flex-direction": "column",
        "align-items": "flex-start"
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.versions, v => {
        return _openBlock(), _createBlock(_component_el_radio, {
          key: v.id,
          label: v.id,
          size: "large"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(v.fileName), 1)]),
          _: 2
        }, 1032, ["label"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue"]), _createVNode(_component_el_row, {
      class: "bothCenter"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        class: "primaryBtn",
        onClick: $options.confirmVersion,
        style: {
          "background-color": "#015EF5",
          "color": "white",
          "border": "none",
          "width": "100px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode("确认 ")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        style: {
          "width": "100px"
        },
        onClick: _cache[1] || (_cache[1] = $event => $data.versionVisible = false)
      }, {
        default: _withCtx(() => [_createTextVNode("取消")]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["visible-control"]), _createVNode(_component_CatalogUploadProcess, {
    ref: "uploadProcess",
    onProcessComplete: $options.onProcessComplete
  }, null, 8, ["onProcessComplete"])], 64);
}