import constants from "@/utils/constants";
export default {
  name: "EditableFileIcon2",
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      }
    },
    share: {
      type: Boolean,
      default: false
    },
    noDeleteBtn: {
      type: Boolean,
      default: false
    },
    eventKey: {
      type: String,
      default: '-'
    }
  },
  methods: {
    fileIcon() {
      return constants.getFileIcon(this.file.fileType == 'FOLDER' ? 'folder' : this.file.fileExt);
    },
    init() {
      // console.log('file url',this.fileUrl)
    },
    showContext(event) {
      setTimeout(() => {
        this.$emit('showContext', event);
      }, 50);
    }
  },
  mounted() {
    this.init();
  }
};