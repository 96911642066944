import "core-js/modules/es.array.push.js";
import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
import FileOperateWrap from "@/components/FileOperateWrap";
import constants from "@/utils/constants";
export default {
  name: "FileSearchView",
  components: {
    BreadCrumb,
    BatterTableDataView,
    FileOperateWrap
  },
  data() {
    return {
      crumb: [{
        name: '共享文件'
      }],
      name: null
    };
  },
  methods: {
    highLightTextLeft(t, k) {
      if (!t) {
        return '';
      }
      if (t.startsWith(k)) {
        return '';
      }
      return t.split(k)[0];
    },
    highLightTextRight(t, k) {
      if (!t) {
        return '';
      }
      if (t.endsWith(k)) {
        return '';
      }
      return t.split(k)[1];
    },
    fileIcon(fileUrl) {
      return constants.getFileIcon(fileUrl);
    },
    fileExtName(f) {
      return constants.getFileType(f);
    },
    onEnterFolder(folder) {
      this.folder = folder.id;
      this.$router.push('/m/share/folder/org/' + folder.id);
    },
    init() {
      if (this.$route.query.name) {
        this.name = this.$route.query.name;
        this.$refs.queryTable.fetchData();
        let thiz = this;
        setTimeout(() => {
          thiz.name = null;
        }, 1000);
      }
    }
  },
  mounted() {
    this.init();
  }
};