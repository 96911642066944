import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "SheetEditor",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      sheet: {},
      property: null,
      properties: [],
      colTypes: [],
      subCatalogType: [],
      sheetRowIndexProxy: 1
    };
  },
  methods: {
    handleRowChange(v) {
      this.sheet.sheetRowIndex = v - 1;
      this.colTypes = this.sheet.colNames[this.sheet.sheetRowIndex].map(() => this.subCatalogType[0].value);
      for (let i in this.sheet.colNames[this.sheet.sheetRowIndex]) {
        this.sheet.description[i] = this.sheet.colNames[this.sheet.sheetRowIndex][i];
      }
    },
    confirmSheet() {
      if (!this.property) {
        ElMessage.error('请选择数据属性');
        return;
      }
      if (!this.sheet.sheetName) {
        ElMessage.error('请输入目录名称');
        return;
      }
      for (let cell in this.sheet.colNames[this.sheet.sheetRowIndex]) {
        let cellValue = this.sheet.colNames[this.sheet.sheetRowIndex][cell];
        if (this.isEmptyContent(cellValue)) {
          ElMessage.error(`第${parseInt(cell) + 1}个子目录为空!`);
          return;
        }
        // if (this.isEmptyContent(this.sheet.example[cell])) {
        //   ElMessage.error(`第${parseInt(cell) + 1}个样例为空!`);
        //   return
        // }
      }

      this.sheet.colTypes = this.colTypes;
      this.sheet.property = this.property;
      this.$emit('nextSheet', this.sheet);
    },
    init() {
      api.getDataCatalogProperityList().then(ret => {
        this.properties = ret;
        api.getEnumOptionList(1).then(ret => {
          this.sheet = Object.assign({}, this.data);
          this.subCatalogType = ret;
          this.colTypes = this.sheet.colNames[this.sheet.sheetRowIndex].map(() => this.subCatalogType[0].value);
          this.sheetRowIndexProxy = this.sheet.sheetRowIndex + 1;
        });
      });
    },
    isEmptyContent(cellValue) {
      return !cellValue || cellValue.length < 1;
    }
  },
  mounted() {
    this.init();
  }
};