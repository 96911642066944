import "core-js/modules/es.array.push.js";
import EditableFileIcon from "@/components/EditableFileIcon";
import DialogCustomView from "@/components/DialogCustomView";
import OssUploader from "@/components/OssUploader";
import { ElMessageBox } from "element-plus";
export default {
  name: "FileUploadDialog",
  components: {
    EditableFileIcon,
    DialogCustomView,
    OssUploader
  },
  data() {
    return {
      warningVisible: false,
      uploaderVisible: false,
      files: [],
      sensitiveKeys: ['处分', '结果', '任免', '调动', '任职', '晋升', '进衔', '绝密', '机密', '内部', '秘密', '敏感'],
      onlySensitiveMode: false
    };
  },
  methods: {
    uploadFiles(files) {
      this.$refs.outOssUploader.updateFiles(files);
      this.onlySensitiveMode = true;
    },
    confirmUpload() {
      this.$emit('onUploadedWithName', this.files);
      this.uploaderVisible = false;
      this.files = [];
      this.onlySensitiveMode = false;
    },
    onRemove(idx) {
      this.files.splice(idx, 1);
    },
    onUploadedWithName(data) {
      let sensitive = false;
      for (let sk of this.sensitiveKeys) {
        if (data.name.includes(sk)) {
          this.warningVisible = true;
          sensitive = true;
        }
      }
      if (this.onlySensitiveMode) {
        if (sensitive) {
          ElMessageBox.confirm('请确认是否上传: ' + data.name, '文件可能存在带有敏感词汇', {
            confirmButtonText: '确认上传',
            cancelButtonText: '取消上传',
            type: 'warning'
          }).then(() => {
            this.$emit('onUploadedWithName', [data]);
          });
        } else {
          this.$emit('onUploadedWithName', [data]);
        }
      } else {
        this.files.push(data);
      }
    },
    show() {
      this.files = [];
      this.onlySensitiveMode = false;
      this.warningVisible = false;
      this.uploaderVisible = true;
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};