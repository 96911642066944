import OrgPeopleItem from "@/components/catalog/OrgPeopleItem";
export default {
  name: "SearchedPeopleList",
  components: {
    OrgPeopleItem
  },
  props: {
    user: {
      type: Array,
      default: () => {
        return [];
      }
    },
    versionId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};