import NumberCard from "@/components/dashboard/NumberCard";
import NumberCardWithSelect from "@/components/dashboard/NumberCardWithSelect";
import BarChart from "@/components/dashboard/BarChart";
import api from "@/api";
import TaskDashboard from "@/components/dashboard/TaskDashboard";
import ResourceGrowthChart from "@/components/catalog/ResourceGrowthChart";
export default {
  name: "ManagerDashboard",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ResourceGrowthChart,
    TaskDashboard,
    BarChart,
    NumberCard,
    NumberCardWithSelect
  },
  data() {
    return {
      barDataType: 'catalogSizes',
      pieDataType: 'catalogSizes',
      baseData: {},
      orgCatalogData: {},
      dataIncrease: null,
      catalogPropertyData: null,
      share: [],
      onlyStations: false
    };
  },
  methods: {
    init() {
      api.getDashboardData().then(ret => {
        this.baseData = ret;
      });
      api.orgCatalogData({
        'onlyStations': this.onlyStations
      }).then(ret => {
        this.orgCatalogData = ret;
      });
      api.getDataIncrease().then(ret => {
        this.dataIncrease = ret;
      });
      api.getCatalogPropertyData().then(ret => {
        this.catalogPropertyData = ret;
      });
      api.getLastestShare(6).then(ret => {
        this.share = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};