import api from "@/api";
import ShareFolderBlock from "@/components/file/ShareFolderBlock";
import ShareFolderFileCard from "@/components/file/ShareFolderFileCard";
export default {
  name: "ShareFolderPreviewView",
  components: {
    ShareFolderFileCard,
    ShareFolderBlock
  },
  props: {
    superIds: {
      type: String,
      default: '74,75'
    }
  },
  watch: {
    superIds() {
      this.init();
    }
  },
  data() {
    return {
      folders: [],
      selected: -1,
      foldersDetailList: []
    };
  },
  methods: {
    toLeft() {
      console.log('to left', this.$refs.rollingContainer);
      this.$refs.rollingContainer[0].style.scrollBehavior = 'smooth';
      this.$refs.rollingContainer[0].scrollLeft -= 500;
    },
    toRight() {
      console.log('to right', this.$refs.rollingContainer);
      this.$refs.rollingContainer[0].style.scrollBehavior = 'smooth';
      this.$refs.rollingContainer[0].scrollLeft += 500;
    },
    selectFolder(ci) {
      this.selected = this.selected == ci ? -1 : ci;
      if (this.selected >= 0) {
        api.getOpenShareFilesByFolder(this.folders[ci].id).then(ret => {
          this.foldersDetailList = ret;
        });
      }
    },
    init() {
      this.selected = -1;
      this.foldersDetailList = [];
      api.getShareFolderBySuperIds(this.superIds).then(ret => {
        this.folders = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};