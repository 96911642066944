import "core-js/modules/es.array.push.js";
import api from "@/api";
import ShareFolderPreviewView from "@/components/file/ShareFolderPreviewView";
import FilePreviewer from "@/components/file/FilePreviewer";
export default {
  name: "ShareFileDashboardView",
  components: {
    ShareFolderPreviewView,
    FilePreviewer
  },
  data() {
    return {
      search: null,
      loading: false,
      options: [],
      departmentFolder: true
    };
  },
  methods: {
    gotoSearchView() {
      this.$router.push('/m/share/folder/search?name=' + this.search);
    },
    goToFolder(folder) {
      this.$refs.previewer.preview(folder);
    },
    onSearchResultChange(e) {
      if (e.length < 1) {
        return;
      }
      this.$router.push('/m/share/folder/org/' + e[0]);
    },
    remoteSearch(v) {
      api.searchShareFileByName({
        name: v
      }).then(ret => {
        this.options = ret;
      });
    }
  },
  mounted() {}
};