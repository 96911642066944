import "core-js/modules/es.array.push.js";
import BreadCrumb from "@/components/BreadCrumb";
import api from "@/api";
import DialogView from "@/components/DialogView";
import uuid from "@/utils/uuid";
import { ElLoading, ElMessage } from "element-plus";
import BatterPageDataView from "@/components/BatterPageDataView";
import IconFileList2 from "@/components/IconFileList2";
import BatterTableDataView from "@/components/BatterTableDataView";
import constants from "@/utils/constants";
import FileOperateWrap from "@/components/FileOperateWrap";
import FileOperateHead from "@/components/file/FileOperateHead";
import DialogCustomView from "@/components/DialogCustomView";
import ArrangeOperate from "@/components/file/ArrangeOperate";
import FileUploadDialog from "@/components/file/FileUploadDialog";
export default {
  name: "PersonalShareFolderView",
  components: {
    FileOperateWrap,
    BatterTableDataView,
    IconFileList2,
    FileUploadDialog,
    DialogView,
    BreadCrumb,
    BatterPageDataView,
    FileOperateHead,
    DialogCustomView,
    ArrangeOperate
  },
  data() {
    return {
      ek: uuid.uuid(),
      crumb: [{
        name: '共享文件'
      }, {
        name: '个人文件夹'
      }],
      rows: null,
      mkdirVisible: false,
      mouseY: 0,
      mouseX: 0,
      ossVersion: 0,
      folder: this.$route.params.folder,
      search: null,
      selectedFiles: [],
      arrangeVisible: false
    };
  },
  computed: {
    ctxe() {
      return this.$store.state.contextMenu.event;
    },
    folderId() {
      return this.$route.params.folder;
    }
  },
  watch: {
    folderId(n) {
      this.folder = n;
      this.init();
    },
    ctxe(n) {
      if (!n || !n.startsWith(this.ek)) {
        return;
      }
      console.log('ctx', n);
      if (n.endsWith('mkdir')) {
        this.mkdirVisible = true;
      } else if (n.endsWith('uploadFile')) {
        this.$refs.fileUploader.show();
      } else if (n.endsWith('f5')) {
        ElMessage.success('已刷新');
        this.init();
      } else if (n.endsWith('arrange')) {
        this.arrangeVisible = true;
      }
    }
  },
  methods: {
    confirmArrange(data) {
      data.folder = this.folder;
      data.inner = false;
      api.arrangeFolder(data).then(() => {
        this.arrangeVisible = false;
      });
    },
    onSearch(t) {
      this.search = t;
    },
    deleteSelected() {
      for (let f of this.selectedFiles) {
        api.deleteFile(f).then(() => {
          ElMessage.warning('已删除');
        });
      }
      setTimeout(this.init, 200);
      // this.selectedFiles = [];
    },

    mergeDownload() {
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.mergeDownload(this.selectedFiles.join(',')).then(ret => {
        window.open(ret, '_blank');
        // this.selectedFiles = [];
      }).finally(() => {
        loading.close();
      });
    },
    onSelectionChange(e) {
      this.selectedFiles = e.map(o => o.id);
    },
    fileIcon(fileUrl) {
      return constants.getFileIcon(fileUrl);
    },
    onEnterFolder(f) {
      this.$router.push('/m/share/folder/personal/' + f.id);
    },
    onUploadedWithName(files) {
      this.ossVersion++;
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = {
        folder: this.folder,
        'files': files
      };
      api.batchCreateMyFile(data).then(() => {
        this.init();
      }).finally(() => {
        loading.close();
      });
    },
    handleDrop(event) {
      event.preventDefault();
      console.log('handleDrop', event.dataTransfer.getData('text/plain'));
      const files = event.dataTransfer.files;
      if (files.length < 1) {
        return;
      }
      // ElMessage.success(files.length + '个文件上传中...')
      this.$refs.fileUploader.uploadFiles(files);
    },
    createMyFolder(data) {
      data.folder = this.folder;
      api.createMyFolder(data).then(() => {
        this.mkdirVisible = false;
        this.init();
      });
    },
    showContextMenu(event) {
      // 阻止默认右键菜单
      event.preventDefault();
      let data = {
        style: {
          top: event.clientY + 'px',
          left: event.clientX + 'px'
        },
        menus: [{
          name: '新建文件夹',
          key: this.ek + '.mkdir'
        }, {
          name: '上传文件',
          key: this.ek + '.uploadFile'
        }, {
          name: '一键整理',
          key: this.ek + '.arrange'
        }, {
          name: '刷新',
          key: this.ek + '.f5'
        }]
      };
      this.$store.commit('showContextMenu', data);
    },
    hideContextMenu() {
      this.$store.commit('hideContextMenu');
    },
    init() {
      this.selectedFiles = [];
      api.getFolderPath(this.folder).then(ret => {
        let path_crumb = [];
        for (let p of ret) {
          path_crumb.push({
            name: p.name,
            link: '/m/share/folder/personal/' + p.folderId
          });
        }
        if (path_crumb[path_crumb.length - 1]) {
          path_crumb[path_crumb.length - 1].link = null;
          this.crumb = path_crumb;
        }
      });
      if (this.$refs.personalDataTable) {
        this.$refs.personalDataTable.fetchData();
      } else if (this.$refs.fileList) {
        this.$refs.fileList.fetchData();
      }
    }
  },
  mounted() {
    this.init();
  }
};