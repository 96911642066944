import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41600939"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "TestViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WorkBookEditor = _resolveComponent("WorkBookEditor");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.sheets.length > 0 ? (_openBlock(), _createBlock(_component_WorkBookEditor, {
    key: 0,
    onCompleteSheetEdit: $options.onCompleteSheetEdit,
    data: $data.sheets
  }, null, 8, ["onCompleteSheetEdit", "data"])) : _createCommentVNode("", true)]);
}