import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-39d217be"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ShareFolderFileCardContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_text = _resolveComponent("el-text");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "opd16 vpd6 ft14 wft mb12",
    style: {
      "width": "200px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_text, {
      truncated: true,
      style: {
        "color": "white"
      },
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('preview', $props.data)),
      class: "ptr"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.data.name), 1)]),
      _: 1
    })]),
    _: 1
  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.subFiles, f => {
    return _openBlock(), _createBlock(_component_el_row, {
      key: f.id,
      class: _normalizeClass({
        'fileItem activedFileItem opd16 vpd8 box': $data.activeId === f.id,
        'fileItem opd16 vpd8 box': $data.activeId !== f.id
      }),
      style: {
        "cursor": "pointer"
      },
      onClick: $event => {
        $data.activeId = f.id;
        _ctx.$emit('preview', f);
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_text, {
        truncated: "",
        style: _normalizeStyle({
          color: $data.activeId == f.id ? 'white' : '#4D5C92'
        })
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(f.name), 1)]),
        _: 2
      }, 1032, ["style"])]),
      _: 2
    }, 1032, ["class", "onClick"]);
  }), 128))]);
}