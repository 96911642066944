import WorkBookEditor from "@/components/catalog/WorkBookEditor";
import api from "@/api";
export default {
  name: "TestView",
  components: {
    WorkBookEditor
  },
  data() {
    return {
      sheets: []
    };
  },
  methods: {
    onCompleteSheetEdit() {},
    init() {
      api.readExcelSheet('https://dreport.oss-cn-hangzhou.aliyuncs.com/2023/06/09/b1cf8749-21a8-4669-8fdd-5032d0c30479.xlsx').then(ret => {
        this.sheets = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};