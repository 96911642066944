import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7d2050ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "FileEditorViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$config;
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_button = _resolveComponent("el-button");
  const _component_DocumentEditor = _resolveComponent("DocumentEditor");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
    class: _normalizeClass((_$data$config = $data.config) !== null && _$data$config !== void 0 && _$data$config.editable ? 'fullScreenBtn' : 'fullScreenBtn2'),
    size: "small",
    onClick: $options.fullScreen
  }, {
    default: _withCtx(() => [_createTextVNode("全屏")]),
    _: 1
  }, 8, ["class", "onClick"]), $data.config != null ? (_openBlock(), _createBlock(_component_DocumentEditor, {
    key: 0,
    ref: "docEditor",
    style: {
      "height": "600px"
    },
    id: "docEditor",
    documentServerUrl: $data.config.server,
    config: $data.config.editConfig,
    events_onAppReady: $options.onAppReady,
    events_onDocumentReady: $options.onDocumentReady,
    events_onError: $options.onDocumentError,
    events_onInfo: $options.onDocumentInfo,
    events_onWarning: $options.onDocumentWarning,
    events_onRequestHistory: $options.onRequestHistory,
    events_onRequestHistoryData: $options.onRequestHistoryData,
    events_onRequestHistoryClose: $options.onRequestHistoryClose
  }, null, 8, ["documentServerUrl", "config", "events_onAppReady", "events_onDocumentReady", "events_onError", "events_onInfo", "events_onWarning", "events_onRequestHistory", "events_onRequestHistoryData", "events_onRequestHistoryClose"])) : _createCommentVNode("", true)])], 64);
}