export default {
  name: "IceFolder",
  props: {
    sub: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '-'
    },
    subFolders: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      showSub: false
    };
  },
  methods: {
    onClick() {
      this.showSub = this.sub ? false : !this.showSub;
      this.$emit('onClick');
    },
    onClickSub(f) {
      console.log('onClickSub', f);
      this.$emit('onClickSub', f);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};