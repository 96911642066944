import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "RecycleFolderView",
  components: {
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '共享文件'
      }, {
        name: '回收站'
      }]
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};