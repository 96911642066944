import "core-js/modules/es.array.push.js";
import DialogCustomView from "@/components/DialogCustomView";
import DialogView from "@/components/DialogView";
import WorkBookEditor from "@/components/catalog/WorkBookEditor";
import { ElLoading, ElMessage } from "element-plus";
import api from "@/api";
export default {
  name: "CatalogUploadProcess",
  components: {
    DialogCustomView,
    DialogView,
    WorkBookEditor
  },
  data() {
    return {
      addMoreVisible: false,
      editSheetVisible: false,
      nonExcelEditVisible: false,
      uploadSubmit: {},
      rawSheet: []
    };
  },
  methods: {
    addMore() {
      this.addMoreVisible = true;
    },
    onCompleteNonExcelEdit(data) {
      let loading = ElLoading.service({
        lock: true,
        text: '系统处理中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let post = {
        orgId: this.uploadSubmit.orgId,
        dataUpdateFrequency: this.uploadSubmit.dataUpdateFrequency,
        dataQuality: this.uploadSubmit.dataQuality,
        url: this.uploadSubmit.file[0],
        fileType: this.uploadSubmit.fileType,
        fillingRequirement: this.uploadSubmit.fillingRequirement,
        dataPropertyTypeId: data.dataPropertyTypeId,
        fileName: data.fileName,
        name: data.name
      };
      api.createNonExcelCatalog(post).then(() => {
        this.nonExcelEditVisible = false;
        this.$emit('processComplete');
      }).finally(() => {
        loading.close();
      });
    },
    onCompleteSheetEdit(data) {
      let loading = ElLoading.service({
        lock: true,
        text: '系统处理中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let req = [];
      for (let sheet of data) {
        let post = {
          orgId: this.uploadSubmit.orgId,
          dataUpdateFrequency: this.uploadSubmit.dataUpdateFrequency,
          dataQuality: this.uploadSubmit.dataQuality,
          fillingRequirement: this.uploadSubmit.fillingRequirement,
          url: this.uploadSubmit.file[0],
          fileType: this.uploadSubmit.fileType,
          sheetIndex: sheet.sheetIndex,
          sheetName: sheet.sheetName,
          dataPropertyTypeId: sheet.property,
          header: sheet.colNames[sheet.sheetRowIndex],
          example: sheet.example,
          colTypes: sheet.colTypes,
          description: sheet.description,
          fieldDataTypes: sheet.fieldDataTypes,
          fileName: sheet.fileName
        };
        req.push(post);
      }
      api.createCatalog(req).then(() => {
        ElMessage.success('上传成功!');
        this.editSheetVisible = false;
        this.$emit('processComplete');
      }).finally(() => {
        loading.close();
      });
    },
    afterUploadFile(data) {
      console.log('afterUploadFile', data);
      if (data.dataUpdateFrequency == null) {
        ElMessage.error('更新周期必选');
        return;
      }
      // if (data.fileType == null) {
      //   ElMessage.error('文件类型必选');
      //   return
      // }
      if (data.dataQuality == null) {
        ElMessage.error('数据质量必选');
        return;
      }
      if (data.file == null) {
        ElMessage.error('文件必传');
        return;
      }
      let loading = ElLoading.service({
        lock: true,
        text: '解析中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.addMoreVisible = false;
      this.uploadSubmit = data;
      api.readFileType(data).then(ret => {
        if (ret == 0) {
          api.readExcelSheet(data.file).then(ret => {
            this.rawSheet = ret;
            this.editSheetVisible = true;
          }).finally(() => {
            loading.close();
          });
        } else if (ret == 1) {
          loading.close();
          this.nonExcelEditVisible = true;
          //文档
        } else if (ret == 2) {
          loading.close();
          this.nonExcelEditVisible = true;
          //ppt
        } else {
          loading.close();
          ElMessage.error('不支持该文件格式');
        }
      });
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};