export default {
  name: "ShareFolderBlock",
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {
          orgId: 0,
          name: '人事处',
          property: '数据属性',
          catalogSize: 100,
          subCatalogSize: 200
        };
      }
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};