const base = {
    baseUrl: "",
    menus: "/api/manager/menu/menuList",
    userInfo: "/api/manager/info/my",
    loginCaptchaToken: "/login/captchaToken",
    login: "/login/accountLogin",
    logout: "/api/user/logout?channel=0",

    getBannerList: "/api/manager/banner/list",
    createBanner: "/api/manager/banner/create",
    updateBanner: "/api/manager/banner/update",
    deleteBanner: "/api/manager/banner/delete?bannerId={0}",

    getOssKeySecret: "/api/manager/oss/getOssKeySecret",
    updateSystemConfig: '/api/manager/config/update',
    getSystemConfig: '/api/manager/config/list',
    updateImageValue: '/api/manager/config/updateImageValue',
    getMapKey: '/api/manager/setting/getMapKey',
    updateOrganizationEnableState: '/api/manager/organization/updateEnableState?id={0}&value={1}',
    createRole: '/api/manager/role/create',
    updateUserEnableState: '/api/manager/user/updateEnableState?id={0}&value={1}',
    updateUserManagerLogin: '/api/manager/user/updateUserManagerLogin?id={0}&value={1}',
    getOrgTreeData: '/api/manager/organization/getOrgTree',
    createOrg: '/api/manager/organization/create',
    updateOrg: '/api/manager/organization/update',
    deleteOrg: '/api/manager/organization/delete?id={0}',
    updateOrgTreeIndex: '/api/manager/organization/updateOrgTreeIndex',
    createEnumValue: '/api/manager/enum/create',
    updateEnumValueEnableState: '/api/manager/enum/updateEnumValueEnableState?id={0}&value={1}',
    getDataCatalogOrgList: '/api/open/catalog/getDataCatalogOrgList',
    readExcelSheet: '/api/manager/excel/readSheet?url={0}',
    getEnumOptionList: '/api/manager/enum/list4select/{0}',
    createCatalog: '/api/manager/catalog/proactively/createCatalog',
    getDataCatalogGroupByProperty: '/api/open/catalog/getDataCatalogGroupByOrg',
    getCatalogById: '/api/manager/catalog/getCatalogById?catalogId={0}',
    searchCatalogByName: '/api/open/catalog/searchCatalogByName',
    getDashboardData: '/api/manager/statistic/baseData',
    orgCatalogData: '/api/manager/statistic/orgCatalogData',
    getDataIncrease: '/api/manager/statistic/getDataIncrease',
    getCatalogPropertyData: '/api/manager/statistic/dataPropertyData',
    getVersionDownloadInfo: '/api/manager/file/download?versionId={0}',
    getVersionReadOnlyToken: '/api/manager/file/read?versionId={0}',
    getLastestShare: '/api/manager/statistic/getLastestShare?size={0}',
    getEditorInfoByFileKey: '/api/manager/file/getEditorInfoByFileKey?fileToken={0}',
    readFileType: '/api/manager/file/readFileType',
    changeFileVersionShareState: '/api/manager/mydata/changeFileVersionShareState',
    changeCatalogShareState: '/api/manager/mydata/changeCatalogShareState',
    createNonExcelCatalog: '/api/manager/catalog/proactively/createNonSheetFile',
    createNewVersion: '/api/manager/catalog/proactively/createNewVersion',
    deleteVersion: '/api/manager/catalog/proactively/deleteVersion?id={0}',
    getCooEditToken: '/api/manager/file/getCooEditToken?versionId={0}',
    getFileEditHistory: '/api/manager/file/getFileEditHistory',
    getFileEditHistoryVersion: '/api/manager/file/getFileEditHistoryVersion',
    collectOrDisCollect: '/api/manager/catalog/collectOrDisCollect?versionId={0}',
    getCreateTaskInitData: '/api/manager/task/createInit',
    getCooEditConfig: '/api/manager/catalog/getCooEditConfig?versionId={0}',
    getCooEditOrgPeopleConfig: '/api/manager/catalog/getCooEditOrgPeopleConfig',
    updateVersionAuth: '/api/manager/catalog/updateVersionAuth',
    updateCooEditOrgAuth: '/api/manager/catalog/updateCooEditOrgAuth',
    searchCooEditUser: '/api/manager/catalog/searchCooEditUser',
    getVersionEditHistory: '/api/manager/catalog/getVersionEditHistory?versionId={0}',
    deleteEditHistory: '/api/manager/catalog/deleteEditHistory?id={0}',
    getCatalogVersionsById: '/api/manager/catalog/getVersionsById?catalogId={0}',
    getUserTree: '/api/manager/user/getUserTree',
    createCatalogTask: '/api/manager/task/create',
    getTaskDetail: '/api/manager/task/getTaskDetail?taskId={0}',
    confirmFinishCoEdit: '/api/manager/task/confirmFinishCoEdit?participantId={0}',
    getCooEditTokenOrReadToken: '/api/manager/file/getCooEditTokenOrReadToken?taskId={0}&versionId={1}',
    auditTask: '/api/manager/task/auditTask',
    storeTask: '/api/manager/task/storeTask',
    getTaskEditInfo: '/api/manager/task/getTaskEditInfo?taskId={0}',
    getCheckedUsers: '/api/manager/task/getCheckedUsers?taskId={0}',
    updateCatalogTask: '/api/manager/task/updateCatalogTask',
    getMeetingInfoById: '/api/manager/meeting/getMeetingInfoById?meetingId={0}',
    updateMeetingAttachments: '/api/manager/meeting/updateMeetingAttachments',
    updateTaskAttachments: '/api/manager/task/updateTaskAttachments',
    getCreateMeetingInitData: '/api/manager/meeting/getCreateMeetingInitData',
    getMeetingCheckedUsers: '/api/manager/meeting/getMeetingCheckedUsers?meetingId={0}',
    createMeeting: '/api/manager/meeting/create',
    updateMeeting: '/api/manager/meeting/update',
    getMeetingEditInfoById: '/api/manager/meeting/getMeetingEditInfoById?meetingId={0}',
    cancelTask: '/api/manager/task/cancel?taskId={0}',
    completeTask: '/api/manager/task/complete?taskId={0}',
    cancelMeeting: '/api/manager/meeting/cancel?meetingId={0}',
    completeMeeting: '/api/manager/meeting/complete?meetingId={0}',
    getFilesBySuper: '/api/manager/share/file/getFilesBySuper',
    getMyFile: '/api/manager/share/file/getMyFile',
    createMyFolder: '/api/manager/share/file/createMyFolder',
    deleteFile: '/api/manager/share/file/deleteFile?fileId={0}',
    createMyFile: '/api/manager/share/file/createMyFile',
    batchCreateMyFile: '/api/manager/share/file/batchCreateMyFile',
    getFileOperates: '/api/manager/setting/fileOperates',
    setFileShareState: '/api/manager/share/file/setFileShareState',
    getFileDownloadLink: '/api/manager/share/file/getFileDownloadLink',
    getFolderPath: '/api/manager/share/file/getFolderPath?folderId={0}',
    moveFile: '/api/manager/share/file/moveFile',
    getFilePreviewLink: '/api/manager/share/file/getFilePreviewLink',
    getFolderOperates: '/api/manager/share/file/getFolderOperates?folderId={0}',
    collectFile: '/api/manager/share/file/collectFile?fileId={0}',
    getFileMoreInfo: '/api/manager/share/file/detail?fileId={0}',
    getFeedbackList: '/api/manager/feedback/list?fileId={0}',
    mergeDownload: '/api/manager/share/file/mergeDownloadShareFile?fileIds={0}',
    updateLimitAccessUser: '/api/manager/share/file/updateLimitAccessUser',
    getAccessLimit: '/api/manager/share/file/getAccessLimit?folderId={0}',
    arrangeFolder: '/api/manager/share/file/arrangeFolder',

    getShareFolderBySuperIds: '/api/open/share/file/folders?superIds={0}',
    getOpenShareFilesByFolder: '/api/open/share/file/files?folderId={0}',
    searchShareFileByName: '/api/open/share/file/search',

    getIceUploadToken: '/api/manager/oss/getIceUploadToken',
    getCooEditTaskList: '/api/manager/task/list',
    getResourceGrowthChartData: '/api/manager/statistic/getResourceGrowthChartData',

    getDingQrLoginIframeUrl: '/login/ding/getDingQrLoginIframeUrl',
    loginByDingAuthCode: '/login/ding/loginByDingAuthCode?authCode={0}',
    bindDingByToken: '/api/manager/user/bindDing?token={0}',

    queryForm: {
        1: '/api/manager/organization/getQueryForm',
        2: '/api/manager/user/getQueryForm',
        3: '/api/manager/catalog/proactively/getQueryForm',
        4: '/api/manager/mydata/getQueryForm',
        5: '/api/manager/catalog/checkout/getQueryForm',
        6: '/api/manager/catalog/proactively/getEmbedQueryForm',
        7: '/api/manager/task/getQueryForm',
        8: '/api/manager/meeting/getQueryForm',
        9: '/api/manager/share/file/getQueryForm',
    },
    dialogs: {
        1: '/api/manager/organization/getUpdateForm?id={0}',
        2: '/api/manager/organization/getCreateForm?superId={0}',
        3: '/api/manager/role/getCreateForm',
        4: '/api/manager/role/getEditForm?roleId={0}',
        5: '/api/manager/role/getCreateForm',
        6: '/api/manager/user/getCreateForm',
        7: '/api/manager/user/getEditForm?userId={0}',
        8: '/api/manager/user/detail?userId={0}',
        9: '/api/manager/enum/getCreateForm?type={0}',
        10: '/api/manager/enum/getUpdateForm?id={0}',
        11: '/api/manager/catalog/proactively/getCreateStep1Form',
        12: '/api/manager/mydata/getCatalogEditForm?catalogId={0}',
        13: '/api/manager/catalog/proactively/getNonExcelCreateDialog',
        14: '/api/manager/catalog/proactively/getCreateNewVersionDialog?versionId={0}',
        15: '/api/manager/share/file/getMyFolderCreateDialog',
        16: '/api/manager/share/file/getFileRenameDialog?fileId={0}',
        17: '/api/manager/share/file/getEncryptFileDialog',
        18: '/api/manager/share/file/getDecryptFileDialog?fileId={0}',
        19: '/api/manager/feedback/getFeedbackDialog?fileId={0}',
        20: '/api/manager/feedback/geReplyDialog?feedId={0}',
        21: '/api/manager/share/file/getCopyFileDialog?fileId={0}',
        22: '/api/manager/share/file/getMoveFileDialog?fileId={0}',
    },
    simpleBtn:{
        1:{
            dialogId:16,
            submitUrl:'/api/manager/share/file/rename',
        },
        2:{
            dialogId:17,
            submitUrl:'/api/manager/share/file/encryptFile',
        },
        3:{
            dialogId:18,
            submitUrl:'/api/manager/share/file/decryptFile',
        },
        4:{
            dialogId:19,
            submitUrl:'/api/manager/feedback/feedback',
        },
        5:{
            dialogId:20,
            submitUrl:'/api/manager/feedback/reply',
        },
        6:{
            dialogId:21,
            submitUrl:'/api/manager/share/file/copyFile',
        },
        7:{
            dialogId:22,
            submitUrl:'/api/manager/share/file/moveFilePost',
        },
        8:{
            dialogId:21,
            submitUrl:'/api/manager/share/file/batchCopyFile',
        },
        9:{
            dialogId:22,
            submitUrl:'/api/manager/share/file/batchMoveFile',
        },
    },
    table: {
        1: {
            queryForm: 1,
            list: '/api/manager/organization/list',
            createDialog: 2,
            create: '/api/manager/organization/create',
            updateDialog: 1,
            update: '/api/manager/organization/update',
            updateKey: 'id',
            delete: '/api/manager/organization/delete',
            deleteKey: 'id'
        },
        2: {
            list: '/api/manager/role/list',
            updateDialog: 4,
            updateKey: 'roleId',
            update: '/api/manager/role/update',
            delete: '/api/manager/role/delete',
            deleteKey: 'roleId',
        },
        3: {
            queryForm: 2,
            list: '/api/manager/user/list',
            createDialog: 6,
            create: '/api/manager/user/create',
            updateDialog: 7,
            update: '/api/manager/user/update',
            updateKey: 'id',
            delete: '/api/manager/user/delete',
            deleteKey: 'id',
            detailDialog: 8,
            detailKey: 'userId'
        },
        4: {
            list: '/api/manager/enum/list',
            updateDialog: 10,
            update: '/api/manager/enum/update',
            updateKey: 'id',
            delete: '/api/manager/enum/delete',
            deleteKey: 'id',
        },
        5: {
            list: '/api/manager/catalog/proactively/list',
            queryForm: 3,
            updateDialog: 12,
            update: '/api/manager/mydata/updateDataCatalog',
            updateKey: 'id',
            delete: '/api/manager/catalog/proactively/delete',
            deleteKey: 'id',
        },
        6: {
            list: '/api/manager/mydata/list',
            queryForm: 4,
            delete: '/api/manager/mydata/delete',
            deleteKey: 'id',
        },
        7: {
            list: '/api/manager/catalog/checkout/list',
            queryForm: 5,
        },
        8: {
            list: '/api/manager/catalog/proactively/myOwnList',
            queryForm: 6,
        },
        9: {
            list: '/api/manager/task/list',
            listMethod:'post',
            queryForm: 7,
            delete: '/api/manager/task/delete',
            deleteKey: 'id',
        },
        10: {
            list: '/api/manager/meeting/list',
            listMethod:'post',
            queryForm: 8,
            delete: '/api/manager/meeting/delete',
            deleteKey: 'id',
        },
        11: {
            list: '/api/manager/share/file/getMyFile',
        },
        12: {
            list: '/api/manager/share/file/listFolder',
        },
        13: {
            list: '/api/manager/share/file/getMyCollection',
        },
        15: {
            list: '/api/manager/share/file/search',
            listMethod:'post',
            queryForm: 9,
        },
        16: {
            list: '/api/manager/share/file/departUserFolders',
        },
    }

}

export default base;