import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ce3173e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    id: $data.chartId,
    style: {
      width: '100%',
      height: '248px'
    },
    ref: "canvas"
  }, null, 8, _hoisted_1);
}