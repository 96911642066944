import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3b26117e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_popconfirm = _resolveComponent("el-popconfirm");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createBlock(_component_el_row, {
    class: "CoEditParticipantContainer smallFont"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6,
      class: "leftCenter"
    }, {
      default: _withCtx(() => {
        var _$props$data, _$props$data$userInfo;
        return [_createElementVNode("img", {
          src: (_$props$data = $props.data) === null || _$props$data === void 0 ? void 0 : (_$props$data$userInfo = _$props$data.userInfo) === null || _$props$data$userInfo === void 0 ? void 0 : _$props$data$userInfo.avatar,
          style: {
            "width": "40px",
            "height": "40px",
            "border-radius": "20px"
          }
        }, null, 8, _hoisted_1)];
      }),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      class: "leftCenter"
    }, {
      default: _withCtx(() => {
        var _$props$data2, _$props$data2$userInf;
        return [_createElementVNode("span", null, _toDisplayString((_$props$data2 = $props.data) === null || _$props$data2 === void 0 ? void 0 : (_$props$data2$userInf = _$props$data2.userInfo) === null || _$props$data2$userInf === void 0 ? void 0 : _$props$data2$userInf.nickName), 1)];
      }),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 10,
      class: "rightCenter"
    }, {
      default: _withCtx(() => {
        var _$props$data3, _$props$data4, _$props$data5, _$props$data6;
        return [((_$props$data3 = $props.data) === null || _$props$data3 === void 0 ? void 0 : _$props$data3.state) == 'INIT' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "待完成")) : _createCommentVNode("", true), ((_$props$data4 = $props.data) === null || _$props$data4 === void 0 ? void 0 : _$props$data4.state) == 'EDITING' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [!((_$props$data5 = $props.data) !== null && _$props$data5 !== void 0 && _$props$data5.me) ? (_openBlock(), _createElementBlock("span", _hoisted_3, "上报中")) : (_openBlock(), _createBlock(_component_el_popconfirm, {
          key: 1,
          title: "确认完成？",
          onConfirm: $options.confirmFinishCoEdit
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            size: "small"
          }, {
            default: _withCtx(() => [_createTextVNode("上报完成")]),
            _: 1
          })]),
          _: 1
        }, 8, ["onConfirm"]))], 64)) : _createCommentVNode("", true), ((_$props$data6 = $props.data) === null || _$props$data6 === void 0 ? void 0 : _$props$data6.state) == 'FINISHED' ? (_openBlock(), _createElementBlock("span", _hoisted_4, "上报完成")) : _createCommentVNode("", true)];
      }),
      _: 1
    })]),
    _: 1
  });
}