import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0102a142"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "FileOperateHeadContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_ButtonDialog = _resolveComponent("ButtonDialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [$props.selectedFiles == null || $props.selectedFiles.length < 1 ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_el_input, {
    placeholder: "请输入关键字",
    modelValue: $data.search,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.search = $event),
    style: {
      "width": "423px"
    },
    class: "mr24",
    "prefix-icon": "Search"
  }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
    class: "optBtn",
    style: {
      "background": "#015EF5"
    },
    onClick: $options.onSearch
  }, {
    default: _withCtx(() => [_createTextVNode("查询")]),
    _: 1
  }, 8, ["onClick"]), $props.write ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    class: "optBtn",
    style: {
      "background": "#2DC76D"
    },
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('upload'))
  }, {
    default: _withCtx(() => [_createTextVNode("上传")]),
    _: 1
  })) : _createCommentVNode("", true), $props.write ? (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    class: "optBtn",
    style: {
      "background": "#2DC76D"
    },
    onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('mkdir'))
  }, {
    default: _withCtx(() => [_createTextVNode("新建文件夹")]),
    _: 1
  })) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_el_button, {
    class: "optBtn",
    onClick: $options.batchCopy
  }, {
    default: _withCtx(() => [_createTextVNode("复制")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    class: "optBtn",
    onClick: $options.batchMove
  }, {
    default: _withCtx(() => [_createTextVNode("移动")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    class: "optBtn",
    onClick: $options.mergeDownload
  }, {
    default: _withCtx(() => [_createTextVNode("下载")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    class: "optBtn",
    onClick: $options.deleteSelected
  }, {
    default: _withCtx(() => [_createTextVNode("删除")]),
    _: 1
  }, 8, ["onClick"])], 64))]), _createVNode(_component_ButtonDialog, {
    "btn-id": 8,
    onFresh: _cache[3] || (_cache[3] = $event => _ctx.$emit('fresh')),
    "bind-id": -1,
    "slot-btn": "",
    "init-data": {
      fileIds: $props.selectedFiles
    },
    ref: "copyFile"
  }, null, 8, ["init-data"]), _createVNode(_component_ButtonDialog, {
    "btn-id": 9,
    onFresh: _cache[4] || (_cache[4] = $event => _ctx.$emit('fresh')),
    "bind-id": -1,
    "slot-btn": "",
    "init-data": {
      fileIds: $props.selectedFiles
    },
    ref: "moveFile"
  }, null, 8, ["init-data"])], 64);
}