import "core-js/modules/es.array.push.js";
import BreadCrumb from "@/components/BreadCrumb";
import VuePdfApp from "vue3-pdf-app";
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";
import api from "@/api";
export default {
  name: "PdfViewer",
  components: {
    VuePdfApp,
    BreadCrumb
  },
  data() {
    return {
      pdfUrl: null,
      pdfName: '',
      fileKey: this.$route.params.fileKey,
      crumb: [{
        name: 'PDF阅读器'
      }]
    };
  },
  methods: {
    init() {
      api.getEditorInfoByFileKey(this.fileKey).then(ret => {
        this.pdfUrl = ret.editConfig.document.url;
        this.crumb.push({
          name: ret.editConfig.document.title
        });
      });
    }
  },
  mounted() {
    this.init();
  }
};