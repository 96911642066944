import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c07f4f70"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "DepartmentFolderViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_ShareFolderPreviewView = _resolveComponent("ShareFolderPreviewView");
  const _component_FilePreviewer = _resolveComponent("FilePreviewer");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "bothCenter pd16"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_input, {
      modelValue: $data.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.search = $event),
      style: {
        "width": "650px"
      },
      "prefix-icon": "Search",
      placeholder: "请输入关键字"
    }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
      type: "primary",
      class: "ml16",
      onClick: $options.gotoSearchView,
      style: {
        "width": "76px"
      }
    }, {
      default: _withCtx(() => [_createTextVNode("查询")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }), _createVNode(_component_ShareFolderPreviewView, {
    onPreview: $options.previewFile,
    "super-ids": "75"
  }, null, 8, ["onPreview"])]), _createVNode(_component_FilePreviewer, {
    ref: "previewer",
    "path-prefix": "/m/share/folder/org/"
  }, null, 512)], 64);
}