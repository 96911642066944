const loadArrayIfNotExist = (thiz, dataKey, pathKey, query) => {
    if (thiz.$store.getters.fetchArrayData(dataKey).length < 1) {
        thiz.$store.dispatch('reloadFetchCache', {path: pathKey, key: dataKey, query: query})
    }
}
const loadObjectIfNotExist = (thiz, dataKey, pathKey, query) => {
    if (Object.keys(thiz.$store.getters.fetchObjectData(dataKey)).length < 1) {
        thiz.$store.dispatch('reloadFetchCache', {path: pathKey, key: dataKey, query: query})
    }
}
export {
    loadArrayIfNotExist,
    loadObjectIfNotExist,
}