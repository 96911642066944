import "core-js/modules/es.array.push.js";
import FileUploadDialog from "@/components/file/FileUploadDialog";
import api from "@/api";
import BatterPageDataView from "@/components/BatterPageDataView";
import IconFileList2 from "@/components/IconFileList2";
import uuid from "@/utils/uuid";
import { ElLoading, ElMessage } from "element-plus";
import DialogView from "@/components/DialogView";
import FileOperateWrap from "@/components/FileOperateWrap";
import BatterTableDataView from "@/components/BatterTableDataView";
import constants from "@/utils/constants";
import FileOperateHead from "@/components/file/FileOperateHead";
import ArrangeOperate from "@/components/file/ArrangeOperate";
import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "InnerShareFolderView",
  components: {
    DialogCustomView,
    ArrangeOperate,
    BatterPageDataView,
    FileUploadDialog,
    BatterTableDataView,
    IconFileList2,
    DialogView,
    FileOperateWrap,
    FileOperateHead
  },
  props: {
    folderId: {
      type: Number,
      default: 0
    },
    folderTitle: {
      type: String,
      default: '处室内共享'
    }
  },
  data() {
    return {
      ek: uuid.uuid(),
      folder: 0,
      folderOperates: null,
      mkdirVisible: false,
      ossVersion: 0,
      selectedFiles: [],
      path: [],
      search: null,
      crumb: [{
        name: '组织内共享文件'
      }],
      arrangeVisible: false
    };
  },
  computed: {
    ctxe() {
      return this.$store.state.contextMenu.event;
    }
  },
  watch: {
    ctxe(n) {
      if (!n || !n.startsWith(this.ek)) {
        return;
      }
      console.log('ctx', n);
      if (n.endsWith('mkdir')) {
        this.mkdirVisible = true;
      } else if (n.endsWith('upload')) {
        this.$refs.fileUploader.show();
      } else if (n.endsWith('arrange')) {
        this.onArrange();
      }
    }
  },
  methods: {
    confirmArrange(data) {
      data.folder = this.folder;
      data.inner = true;
      api.arrangeFolder(data).then(() => {
        this.arrangeVisible = false;
      });
    },
    onArrange() {
      //一键整理
      this.arrangeVisible = true;
    },
    goBack() {
      this.folder = this.path[this.path.length - 2].folderId;
      // console.log('goback', this.folder)
      this.init(true);
    },
    onSearch(t) {
      this.search = t;
    },
    deleteSelected() {
      for (let f of this.selectedFiles) {
        api.deleteFile(f).then(() => {
          ElMessage.warning('已删除');
        });
      }
      setTimeout(this.init, 200);
      // this.selectedFiles = [];
    },

    mergeDownload() {
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.mergeDownload(this.selectedFiles.join(',')).then(ret => {
        window.open(ret, '_blank');
        // this.selectedFiles = [];
      }).finally(() => {
        loading.close();
      });
    },
    onSelectionChange(e) {
      this.selectedFiles = e.map(o => o.id);
    },
    fileExtName(f) {
      return constants.getFileType(f);
    },
    fileIcon(fileUrl) {
      return constants.getFileIcon(fileUrl);
    },
    onEnterFolder(f) {
      this.$emit('enterFolder', f);
      // this.folder = f.id;
      // this.init()
    },

    onUploadedWithName(files) {
      this.ossVersion++;
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = {
        folder: this.folder,
        'files': files,
        inner: true
      };
      api.batchCreateMyFile(data).then(() => {
        this.reFetch();
      }).finally(() => {
        loading.close();
      });
    },
    handleDrop(event) {
      event.preventDefault();
      if (!this.folderOperates) {
        ElMessage.error('您没有在此文件夹下上传文件的权限');
        return;
      }
      let dragData = event.dataTransfer.getData('text/plain');
      if (dragData != null && dragData.startsWith('DRAG_')) {
        //把文件放进文件夹操作
        api.moveFile({
          fileId: dragData.substring(5),
          folderId: this.folder,
          inner: true
        }).then(() => {
          this.init();
          this.$emit('fresh');
        });
      } else {
        const files = event.dataTransfer.files;
        // ElMessage.success(files.length + '个文件上传中...')
        this.$refs.fileUploader.uploadFiles(files);
      }
    },
    createMyFolder(data) {
      data.folder = this.folder;
      data.inner = true;
      api.createMyFolder(data).then(() => {
        this.mkdirVisible = false;
        this.init();
      });
    },
    showContextMenu(event) {
      // 阻止默认右键菜单
      event.preventDefault();
      if (!this.folderOperates) {
        return;
      }
      let data = {
        style: {
          top: event.clientY + 'px',
          left: event.clientX + 'px'
        },
        menus: this.folderOperates
      };
      this.$store.commit('showContextMenu', data);
    },
    hideContextMenu() {
      this.$store.commit('hideContextMenu');
    },
    init(noFetch) {
      api.getFolderPath(this.folder).then(ret => {
        this.path = ret;
      });
      api.getFolderOperates(this.folder).then(ret => {
        if (!ret.ops) {
          this.folderOperates = null;
          return;
        }
        if (ret.ops.includes('upload')) {
          this.inner = true;
        }
        this.folderOperates = [];
        for (let op of ret.ops) {
          this.folderOperates.push({
            name: ret.map[op],
            key: this.ek + op
          });
        }
      });
      if (!noFetch) {
        this.reFetch();
      }
      this.$emit('fresh');
    },
    reFetch() {
      this.selectedFiles = [];
      setTimeout(() => {
        if (this.$refs.innerFileList) {
          this.$refs.innerFileList.fetchData();
        } else if (this.$refs.innerFileTable) {
          this.$refs.innerFileTable.fetchData();
        }
      }, 100);
    }
  },
  mounted() {
    this.folder = this.folderId;
    this.init();
  }
};