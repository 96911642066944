import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
import CatalogUploadProcess from "@/components/catalog/CatalogUploadProcess";
export default {
  name: "ProactivelyReportingView",
  components: {
    CatalogUploadProcess,
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '数据目录'
      }, {
        name: '数据上报'
      }]
    };
  },
  methods: {
    onProcessComplete() {
      this.$refs.fileTable.fetchData();
    },
    onAddMore() {
      this.$refs.uploadProcess.addMore();
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};