import api from "@/api";
import { ElMessage, ElNotification } from "element-plus";
export default {
  name: "OssUploader",
  props: {
    allowedFileTypes: {
      type: Array,
      default: () => {
        return ['jpeg', 'png', 'jpg', 'gif', 'mp4', 'zip'];
      }
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      ossClient: null,
      ossDomain: null,
      ossFolder: null,
      uploadToken: null
    };
  },
  methods: {
    beforeUpload(file) {
      if (this.allowedFileTypes[0] == '*') {
        return true;
      }
      let allowed = false;
      for (let t of this.allowedFileTypes) {
        if (file.name.endsWith(t)) {
          allowed = true;
        }
      }
      if (!allowed) {
        console.log('file.type', file.type, file.name);
        ElMessage.error('仅支持' + this.allowedFileTypes.join(',') + '格式');
        return false;
      }
      return true;
    },
    initOssClint() {
      if (this.ossInitState) {
        console.log('OSS已经完成初始化!');
        return;
      }
      this.ossInitState = true;
      let OSS = require('ali-oss');
      api.getOssKeySecret().then(cfg => {
        this.ossClient = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true,
          refreshSTSToken: async () => {
            const rt = await api.getOssKeySecret();
            return {
              accessKeyId: rt.accessKeyId,
              accessKeySecret: rt.accessKeySecret,
              stsToken: rt.securityToken
            };
          }
        });
        this.ossDomain = cfg.domain;
        this.ossFolder = cfg.folder;
      });
    },
    updateFiles(files) {
      let u = 0;
      let thiz = this;
      for (let f of files) {
        setTimeout(() => {
          thiz.updateFile(f);
        }, u * 50);
        u++;
      }
    },
    updateFile(file) {
      this.uploadRequest({
        file: file,
        onSuccess: res => {
          let url = res.url;
          if (this.ossDomain) {
            url = this.ossDomain + '/' + res.name;
          }
          this.$emit('onUploaded', url);
          this.$emit('onUploadedWithName', {
            url: url,
            name: file.name
          });
          this.$refs.eluloader.clearFiles();
        },
        onError: () => {}
      });
    },
    handleImageUploadSuccess(url, r, f) {
      ElNotification.success({
        title: '上传成功',
        message: f.name + '已上传成功!'
      });
      if (this.ossDomain) {
        url = this.ossDomain + '/' + r.name;
      }
      this.$emit('onUploaded', url);
      this.$emit('onUploadedWithName', {
        url: url,
        name: f.name
      });
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    uploadRequest(options) {
      try {
        let file = options.file; // 拿到 file
        let guid = this.guid();
        let notification = ElNotification.warning({
          title: file.name,
          dangerouslyUseHTMLString: true,
          message: `<span id='${guid}'>正在开始上传</span>`,
          duration: 0,
          showClose: false
        });
        const progress = p => {
          // Object的上传进度。
          document.getElementById(guid).textContent = `上传进度:${(p * 100).toFixed(0)}%`;
        };
        let fileType = file.name.substr(file.name.lastIndexOf('.'));
        let fileName = (this.ossFolder ? this.ossFolder + '/' : 'user-upload/') + guid + fileType;

        //传到业务服务器
        var xhr = new XMLHttpRequest();
        var formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        formData.append('token', this.uploadToken);
        // 监听 progress 事件
        xhr.upload.addEventListener('progress', function (e) {
          if (e.lengthComputable) {
            progress(e.loaded / e.total);
          }
        }, false);

        // 设置请求完成后的回调函数
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4 && xhr.status == 200) {
            var response = JSON.parse(xhr.responseText);
            // 文件上传成功后的操作
            options.onSuccess({
              url: response.data,
              name: fileName
            });
            notification.close();
          }
        };
        // 发送请求
        xhr.open('POST', '/api/uploader/upload', true);
        xhr.send(formData);

        // // 上传文件,这里是上传到OSS的 uploads文件夹下
        // this.ossClient.multipartUpload(fileName, file, {progress,})
        //     .then(res => {
        //       if (res.res.statusCode === 200) {
        //         res.url = this.ossDomain + '/' + res.name;
        //         options.onSuccess(res);
        //         notification.close();
        //       } else {
        //         options.onError("上传失败");
        //         notification.close();
        //       }
        //     }).finally(() => {
        // })
      } catch (e) {
        console.log('上传失败 fff', options, e);
        options.onError("上传失败");
      }
    }
  },
  mounted() {
    // this.initOssClint();
    api.getIceUploadToken().then(ret => {
      this.uploadToken = ret;
    });
  }
};