import constants from "@/utils/constants";
export default {
  name: "EditableFileIcon",
  props: {
    customIcon: {
      type: String,
      default: null
    },
    share: {
      type: Boolean,
      default: false
    },
    noDeleteBtn: {
      type: Boolean,
      default: false
    },
    fileUrl: {
      type: String,
      default: null
    }
  },
  methods: {
    fileIcon() {
      return constants.getFileIcon(this.fileUrl);
    },
    init() {
      // console.log('file url',this.fileUrl)
    }
  },
  mounted() {
    this.init();
  }
};