import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-25627718"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ShareFolderPreviewViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ShareFolderBlock = _resolveComponent("ShareFolderBlock");
  const _component_el_col = _resolveComponent("el-col");
  const _component_SvgIcon = _resolveComponent("SvgIcon");
  const _component_ShareFolderFileCard = _resolveComponent("ShareFolderFileCard");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "bothCenter"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      class: "leftCenter",
      style: {
        "max-width": "1024px"
      },
      gutter: 32
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.folders, (c, ci) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: c.orgId
        }, [_createVNode(_component_el_col, {
          span: 8,
          class: _normalizeClass(ci > 2 ? 'bothCenter mt32' : 'bothCenter')
        }, {
          default: _withCtx(() => [_createVNode(_component_ShareFolderBlock, {
            data: c,
            selected: $data.selected == ci,
            onGoToFolder: $event => _ctx.$emit('preview', c),
            onSelectFolder: $event => $options.selectFolder(ci)
          }, null, 8, ["data", "selected", "onGoToFolder", "onSelectFolder"])]),
          _: 2
        }, 1032, ["class"]), ((ci + 1) % 3 == 0 || ci == $data.folders.length - 1 && $data.folders.length % 3 != 0) && $data.selected >= 0 && parseInt($data.selected / 3) == parseInt(ci / 3) ? (_openBlock(), _createBlock(_component_el_col, {
          key: 0,
          span: 24,
          class: "leftCenter"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "bothCenter"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 8,
              class: "bothCenter"
            }, {
              default: _withCtx(() => [($data.selected + 1) % 3 == 1 ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 0,
                "icon-class": "up",
                size: "32px"
              })) : _createCommentVNode("", true)]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8,
              class: "bothCenter"
            }, {
              default: _withCtx(() => [($data.selected + 1) % 3 == 2 ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 0,
                "icon-class": "up",
                size: "32px"
              })) : _createCommentVNode("", true)]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8,
              class: "bothCenter"
            }, {
              default: _withCtx(() => [($data.selected + 1) % 3 == 0 ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 0,
                "icon-class": "up",
                size: "32px"
              })) : _createCommentVNode("", true)]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24,
              class: "bothCenter",
              style: {
                "position": "relative"
              }
            }, {
              default: _withCtx(() => [$data.foldersDetailList && $data.foldersDetailList.length > 3 ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createElementVNode("img", {
                src: "https://cdn.waityou.online/75029bd8-2ebc-cd23-2290-76e7b7406b0a.svg",
                onClick: _cache[0] || (_cache[0] = (...args) => $options.toLeft && $options.toLeft(...args)),
                class: "ptr",
                style: {
                  "position": "absolute",
                  "top": "44%",
                  "left": "-50px",
                  "width": "50px"
                }
              }), _createElementVNode("img", {
                src: "https://cdn.waityou.online/2607759b-6555-a675-08b9-06a52379a09c.svg",
                onClick: _cache[1] || (_cache[1] = (...args) => $options.toRight && $options.toRight(...args)),
                class: "ptr",
                style: {
                  "position": "absolute",
                  "top": "44%",
                  "right": "-50px",
                  "width": "50px"
                }
              })], 64)) : _createCommentVNode("", true), _createElementVNode("div", {
                class: "rollingContainer",
                ref_for: true,
                ref: "rollingContainer"
              }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.foldersDetailList, p => {
                return _openBlock(), _createElementBlock("div", {
                  class: "rollingItem",
                  key: p.id
                }, [_createVNode(_component_ShareFolderFileCard, {
                  data: p,
                  onPreview: _cache[2] || (_cache[2] = e => _ctx.$emit('preview', e))
                }, null, 8, ["data"])]);
              }), 128))], 512)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true)], 64);
      }), 128))]),
      _: 1
    })]),
    _: 1
  })]);
}