import "core-js/modules/es.array.push.js";
import api from "@/api";
import { mapMutations } from "vuex";
import md5 from 'js-md5';
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "LoginPage",
  data() {
    return {
      loginMode: 1,
      loginModel: {
        account: null,
        password: null
      },
      passwordError: null,
      accountError: null,
      dingLogin: null,
      dingLoginBlock: 0
    };
  },
  methods: {
    ...mapMutations(['updateUser']),
    loginByDingAuthCode(code) {
      if (this.dingLoginBlock > 0) {
        return;
      }
      this.dingLoginBlock++;
      let loading = ElLoading.service({
        lock: true,
        text: '登录中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.loginByDingAuthCode(code).then(ret => {
        if (ret.action == 0) {
          this.dingToken = ret.dingOpenId;
          this.loginMode = 0;
          ElMessage.warning('您未绑定任何账户，请使用账号密码登录');
        } else {
          localStorage.setItem('access_token', ret.accessToken);
          this.updateUser(ret);
          this.goNext(ret);
        }
      }).finally(() => {
        loading.close();
      });
    },
    doLogin() {
      let verify = true;
      if (!this.loginModel.account) {
        this.accountError = '账号必填';
        verify = false;
      }
      if (!this.loginModel.password) {
        this.passwordError = '密码必填';
        verify = false;
      }
      if (!verify) {
        return;
      }
      this.accountError = null;
      this.passwordError = null;
      api.login({
        phone: this.loginModel.account,
        password: md5(this.loginModel.password),
        channel: 0
      }).then(ret => {
        localStorage.setItem('access_token', ret.accessToken);
        this.updateUser(ret);
        if (this.dingToken) {
          ElMessageBox.confirm('绑定后下次扫码可直接登录', '是否绑定此浙政钉账号?', {
            confirmButtonText: '好的',
            cancelButtonText: '下次吧',
            type: 'warning'
          }).then(() => {
            api.bindDingByToken(this.dingToken).finally(() => {
              this.goNext(ret);
            });
          }).catch(() => {
            this.goNext(ret);
          });
        } else {
          this.goNext(ret);
        }
      }).catch(err => {
        console.log('login err', err);
      });
    },
    goNext(ret) {
      // 取到原页面路径
      let url = this.$route.query.redirect;
      // 跳转回原页面
      this.$router.push({
        path: decodeURIComponent(url ? url : ret.index)
      });
    }
  },
  mounted() {
    api.getDingQrLoginIframeUrl().then(ret => {
      this.dingLogin = ret;
    });
    const thiz = this;
    window.addEventListener('message', function (event) {
      if (event.data.code) {
        thiz.loginByDingAuthCode(event.data.code);
      }
    });
  }
};