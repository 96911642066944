import BreadCrumb from "@/components/BreadCrumb";
import uuid from "@/utils/uuid";
import { ElLoading, ElMessage } from "element-plus";
import BatterPageDataView from "@/components/BatterPageDataView";
import IconFileList2 from "@/components/IconFileList2";
import api from "@/api";
import FileOperateHead from "@/components/file/FileOperateHead";
import BatterTableDataView from "@/components/BatterTableDataView";
import FileOperateWrap from "@/components/FileOperateWrap";
import constants from "@/utils/constants";
export default {
  name: "UserCollectionFolderView",
  components: {
    IconFileList2,
    BreadCrumb,
    BatterPageDataView,
    FileOperateHead,
    BatterTableDataView,
    FileOperateWrap
  },
  data() {
    return {
      ek: uuid.uuid(),
      crumb: [{
        name: '共享文件'
      }, {
        name: '收藏文件夹'
      }],
      rows: null,
      selectedFiles: [],
      search: '',
      folder: 0
    };
  },
  computed: {
    ctxe() {
      return this.$store.state.contextMenu.event;
    }
  },
  watch: {
    ctxe(n) {
      if (!n || !n.startsWith(this.ek)) {
        return;
      }
      console.log('ctx', n);
      if (n.endsWith('mkdir')) {
        this.mkdirVisible = true;
      } else if (n.endsWith('uploadFile')) {
        this.$refs.outOssUploaderTrigger.click();
      }
    }
  },
  methods: {
    onSearch(t) {
      this.search = t;
    },
    deleteSelected() {
      for (let f of this.selectedFiles) {
        api.deleteFile(f).then(() => {
          ElMessage.warning('已删除');
        });
      }
      setTimeout(this.init, 200);
      // this.selectedFiles = [];
    },

    fileExtName(f) {
      return constants.getFileType(f);
    },
    fileIcon(fileUrl) {
      return constants.getFileIcon(fileUrl);
    },
    mergeDownload() {
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.mergeDownload(this.selectedFiles.join(',')).then(ret => {
        window.open(ret, '_blank');
        // this.selectedFiles = [];
      }).finally(() => {
        loading.close();
      });
    },
    onSelectionChange(e) {
      this.selectedFiles = e.map(o => o.id);
    },
    handleDrop(event) {
      event.preventDefault();
      ElMessage.error('收藏文件夹无法上传文件');
    },
    init() {
      if (this.$refs.collectedFileList) {
        this.$refs.collectedFileList.fetchData();
      } else {
        this.$refs.collectedFileTable.fetchData();
      }
    }
  },
  mounted() {
    this.init();
  }
};