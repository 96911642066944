import api from "@/api";
export default {
  name: "LimitAccessEditor",
  props: {
    selected: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      filterText: null,
      userTree: [],
      prop: {
        children: 'children',
        label: 'label'
      }
    };
  },
  watch: {
    filterText(v) {
      this.$refs.userTree.filter(v);
    }
  },
  methods: {
    confirm() {
      this.$emit('checkedUsers', this.$refs.userTree.getCheckedKeys().filter(u => u < 0));
    },
    // onChecked(a, b) {
    //   this.$emit('checkedUsers', b.checkedKeys.filter(u => u < 0));
    // },
    resetCheckbox() {
      this.$refs.userTree.setChecked(1, false, true);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.includes(value);
    },
    initCheckUsers() {
      console.log('setCheckedKeys', this.selected);
      this.$refs.userTree.setCheckedKeys(this.selected);
    },
    init() {
      api.getUserTree().then(ret => {
        this.userTree = [ret];
        this.initCheckUsers();
      });
    }
  },
  mounted() {
    this.init();
  }
};