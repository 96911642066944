import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
import DialogView from "@/components/DialogView";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "UserRoleListView",
  components: {
    BatterTableDataView,
    DialogView,
    BreadCrumb
  },
  data() {
    return {
      createVisible: false,
      crumb: [{
        name: '角色管理'
      }]
    };
  },
  methods: {
    addMore() {
      this.createVisible = true;
    },
    createRole(data) {
      api.createRole(data).then(() => {
        this.createVisible = false;
        this.$refs.roleTable.fetchData();
      });
    }
  }
};