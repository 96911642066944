import BreadCrumb from "@/components/BreadCrumb";
import CatalogInfoTable from "@/components/catalog/CatalogInfoTable";
import CatalogVersionTable from "@/components/catalog/CatalogVersionTable";
import CatalogDataInfoTable from "@/components/catalog/CatalogDataInfoTable";
import api from "@/api";
export default {
  name: "CatalogDetailView",
  components: {
    CatalogDataInfoTable,
    CatalogInfoTable,
    CatalogVersionTable,
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '数据目录',
        link: '/m/my/data'
      }, {
        name: '数据提取'
      }],
      catalog: {},
      catalogId: this.$route.params.catalogId
    };
  },
  methods: {
    init() {
      api.getCatalogById(this.catalogId).then(ret => {
        this.catalog = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};