import BreadCrumb from "@/components/BreadCrumb";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "CatalogCheckoutView",
  components: {
    BatterTableDataView,
    BreadCrumb
  },
  data() {
    return {
      initQuery: {},
      orgId: this.$route.query.orgId,
      addMoreVisible: false,
      editSheetVisible: false,
      crumb: [{
        name: '数据目录'
      }, {
        name: '数据提取'
      }],
      uploadSubmit: {},
      rawSheet: []
    };
  },
  methods: {
    init() {
      if (this.orgId != null) {
        this.initQuery = {
          'orgId': this.orgId
        };
      }
    }
  },
  mounted() {
    this.init();
  }
};