import "core-js/modules/es.array.push.js";
import OssUploader from "@/components/OssUploader";
export default {
  name: "AttachmentFiles",
  components: {
    OssUploader
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    attachments: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      uploadVersion: 0
    };
  },
  methods: {
    onUploadedWithName(file) {
      let files = [];
      Object.assign(files, this.attachments);
      files.push(file);
      this.uploadVersion++;
      this.$emit('updateAttachment', files);
    },
    deleteAttachmentFile(pos) {
      let files = [];
      Object.assign(files, this.attachments);
      files.splice(pos, 1);
      this.$emit('updateAttachment', files);
    },
    downloadAttachment(d) {
      window.open(d.url, '_blank');
    },
    isPdf(url) {
      return url ? url.endsWith('pdf') : false;
    },
    isExcel(url) {
      return url ? url.endsWith('xls') || url.endsWith('xlsx') : false;
    },
    isWord(url) {
      return url ? url.endsWith('doc') || url.endsWith('docx') : false;
    },
    isPPT(url) {
      return url ? url.endsWith('ppt') || url.endsWith('pptx') : false;
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};