import "core-js/modules/es.array.push.js";
import CooEditorSelectView from "@/views/task/CooEditorSelectView";
import BreadCrumb from "@/components/BreadCrumb";
import api from "@/api";
import AttachmentFiles from "@/components/AttachmentFiles";
export default {
  name: "MeetingCreateView",
  components: {
    BreadCrumb,
    CooEditorSelectView,
    AttachmentFiles
  },
  data() {
    return {
      crumb: [{
        name: '会议管理',
        link: '/m/meeting/list'
      }, {
        name: '新建会议'
      }],
      meetingId: this.$route.query.meetingId,
      focusOn: 0,
      meeting: {
        meetingType: 0,
        allDay: false,
        dingRemindType: 0,
        smsRemindType: 0,
        dingRemindTime: 0,
        smsRemindTime: 0,
        attachments: []
      },
      meetingTypes: {},
      remindTimes: [],
      repeatTypes: {}
    };
  },
  methods: {
    updateAttachment(files) {
      this.meeting.attachments = files;
    },
    onCheckedUsers(ids) {
      this.meeting.checkedUsers = ids;
    },
    submit() {
      if (this.meetingId) {
        this.meeting.meetingId = this.meetingId;
        api.updateMeeting(this.meeting).then(() => {
          this.$router.push('/m/meeting/detail/' + this.meetingId);
        });
      } else {
        api.createMeeting(this.meeting).then(ret => {
          this.$router.push('/m/meeting/detail/' + ret.id);
        });
      }
    },
    init() {
      api.getCreateMeetingInitData().then(ret => {
        this.remindTimes = ret.remindTimes;
        this.meetingTypes = ret.meetingTypes;
        this.repeatTypes = ret.repeatTypes;
      });
    }
  },
  mounted() {
    this.init();
    if (this.meetingId != null) {
      api.getMeetingEditInfoById(this.meetingId).then(ret => {
        this.meeting = ret;
      });
    }
  }
};