import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cdf681fe"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "OrgSelectorContainer smallFont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OrgSelectorItem = _resolveComponent("OrgSelectorItem");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.orgs, (o, oi) => {
    return _openBlock(), _createBlock(_component_OrgSelectorItem, {
      key: oi,
      org: o,
      versionId: $props.versionId,
      onSelectOrg: _cache[0] || (_cache[0] = oid => _ctx.$emit('selectOrg', oid)),
      onCooEditOrgAuthChange: _cache[1] || (_cache[1] = $event => _ctx.$emit('CooEditOrgAuthChange')),
      selectedOrgId: $props.selectedOrgId
    }, null, 8, ["org", "versionId", "selectedOrgId"]);
  }), 128))]);
}