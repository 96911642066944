import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DialogView = _resolveComponent("DialogView");
  const _component_WorkBookEditor = _resolveComponent("WorkBookEditor");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_DialogView, {
    "data-id": 11,
    "visible-control": $data.addMoreVisible,
    onClose: _cache[0] || (_cache[0] = $event => $data.addMoreVisible = false),
    onSubmit: $options.afterUploadFile
  }, null, 8, ["visible-control", "onSubmit"]), _createVNode(_component_DialogView, {
    "data-id": 13,
    "visible-control": $data.nonExcelEditVisible,
    onClose: _cache[1] || (_cache[1] = $event => $data.nonExcelEditVisible = false),
    onSubmit: $options.onCompleteNonExcelEdit
  }, null, 8, ["visible-control", "onSubmit"]), _createVNode(_component_DialogCustomView, {
    title: "数据处理",
    width: "90%",
    "visible-control": $data.editSheetVisible,
    onClose: _cache[2] || (_cache[2] = $event => $data.editSheetVisible = false)
  }, {
    default: _withCtx(() => [$data.rawSheet.length > 0 ? (_openBlock(), _createBlock(_component_WorkBookEditor, {
      key: 0,
      onCompleteSheetEdit: $options.onCompleteSheetEdit,
      data: $data.rawSheet
    }, null, 8, ["onCompleteSheetEdit", "data"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible-control"])], 64);
}