import "core-js/modules/es.array.push.js";
import ShareFolderPreviewView from "@/components/file/ShareFolderPreviewView";
import { ElMessage } from "element-plus";
import FilePreviewer from "@/components/file/FilePreviewer";
export default {
  name: "DepartmentFolderView",
  components: {
    FilePreviewer,
    ShareFolderPreviewView
  },
  data() {
    return {
      search: ''
    };
  },
  methods: {
    previewFile(file) {
      this.$refs.previewer.preview(file);
    },
    gotoSearchView() {
      if (this.search.length > 0) {
        this.$router.push('/m/share/folder/search?name=' + this.search);
      } else {
        ElMessage.warning('请输入搜索关键字');
      }
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};