import api from "@/api";
export default {
  name: "CoEditParticipant",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    confirmFinishCoEdit() {
      api.confirmFinishCoEdit(this.data.id).then(() => {
        this.$emit('refresh');
      });
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};