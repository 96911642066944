import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
import DialogCustomView from "@/components/DialogCustomView";
import { ElMessage } from "element-plus";
import CatalogUploadProcess from "@/components/catalog/CatalogUploadProcess";
export default {
  name: "CatalogSelectView",
  components: {
    DialogCustomView,
    BatterTableDataView,
    CatalogUploadProcess
  },
  data() {
    return {
      versionVisible: false,
      versions: [],
      versionId: null
    };
  },
  methods: {
    onProcessComplete() {
      this.$refs.fileTable.fetchData();
    },
    onAddMore() {
      this.$refs.uploadProcess.addMore();
    },
    confirmVersion() {
      if (!this.versionId) {
        ElMessage.error('必须要选择1个版本!');
        return;
      }
      this.versionVisible = false;
      this.$emit('selectedVersion', this.versionId);
      this.versionId = null;
    },
    selectVersion(catalogId) {
      api.getCatalogVersionsById(catalogId).then(ret => {
        this.versions = ret;
        this.versionVisible = true;
      });
    },
    onDetailData(e) {
      window.open('/#/m/catalog/detail/' + e.id);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};