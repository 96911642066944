import "core-js/modules/es.array.push.js";
import BreadCrumb from "@/components/BreadCrumb";
import api from "@/api";
import BatterPageDataView from "@/components/BatterPageDataView";
import IconFileList2 from "@/components/IconFileList2";
import uuid from "@/utils/uuid";
import { ElLoading, ElMessage } from "element-plus";
import DialogView from "@/components/DialogView";
import FileOperateWrap from "@/components/FileOperateWrap";
import BatterTableDataView from "@/components/BatterTableDataView";
import constants from "@/utils/constants";
import FileOperateHead from "@/components/file/FileOperateHead";
import InnerShareFolderView from "@/views/folder/InnerShareFolderView";
import DialogCustomView from "@/components/DialogCustomView";
import ArrangeOperate from "@/components/file/ArrangeOperate";
import FileUploadDialog from "@/components/file/FileUploadDialog";
export default {
  name: "BattalionShareFolderView",
  components: {
    BreadCrumb,
    BatterPageDataView,
    FileUploadDialog,
    IconFileList2,
    DialogView,
    FileOperateWrap,
    BatterTableDataView,
    FileOperateHead,
    InnerShareFolderView,
    DialogCustomView,
    ArrangeOperate
  },
  data() {
    return {
      tab: 0,
      ek: uuid.uuid(),
      crumb: [{
        name: '共享文件'
      }],
      rows: [null],
      folder: this.$route.params.folder < 1 ? 76 : this.$route.params.folder,
      folderOperates: null,
      mkdirVisible: false,
      arrangeVisible: false,
      ossVersion: 0,
      selectedFiles: [],
      search: null,
      inner: false,
      folderWrite: false,
      innerFolderTitle: '大队内共享'
    };
  },
  computed: {
    ctxe() {
      return this.$store.state.contextMenu.event;
    },
    folderId() {
      return this.$route.params.folder;
    }
  },
  watch: {
    folderId(n) {
      this.folder = n;
      this.init();
    },
    ctxe(n) {
      if (!n || !n.startsWith(this.ek)) {
        return;
      }
      console.log('ctx', n);
      if (n.endsWith('mkdir')) {
        this.mkdirVisible = true;
      } else if (n.endsWith('upload')) {
        this.$refs.fileUploader.show();
      } else if (n.endsWith('arrange')) {
        this.onArrange();
      }
    }
  },
  methods: {
    onArrange() {
      //一键整理
      this.arrangeVisible = true;
    },
    confirmArrange(data) {
      data.folder = this.folder;
      data.inner = false;
      api.arrangeFolder(data).then(() => {
        this.arrangeVisible = false;
      });
    },
    onSearch(t) {
      this.search = t;
    },
    onSelectionChange(e) {
      this.selectedFiles = e.map(o => o.id);
    },
    fileExtName(f) {
      return constants.getFileType(f);
    },
    fileIcon(fileUrl) {
      return constants.getFileIcon(fileUrl);
    },
    onEnterFolder(folder) {
      this.$router.push('/m/share/folder/battalion/' + folder.id);
      this.tab = 0;
    },
    onUploadedWithName(files) {
      this.ossVersion++;
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = {
        folder: this.folder,
        'files': files
      };
      api.batchCreateMyFile(data).then(() => {
        this.reFetch();
      }).finally(() => {
        loading.close();
      });
    },
    handleDrop(event) {
      event.preventDefault();
      if (!this.folderOperates || !this.folderWrite) {
        ElMessage.error('您没有在此文件夹下上传文件的权限');
        return;
      }
      let dragData = event.dataTransfer.getData('text/plain');
      if (dragData != null && dragData.startsWith('DRAG_')) {
        //把文件放进文件夹操作
        api.moveFile({
          fileId: dragData.substring(5),
          folderId: this.folder
        }).then(() => {
          this.reFetch();
        });
      } else {
        const files = event.dataTransfer.files;
        if (files.length < 1) {
          return;
        }
        // ElMessage.success(files.length + '个文件上传中...')
        this.$refs.fileUploader.uploadFiles(files);
      }
    },
    createMyFolder(data) {
      data.folder = this.folder < 1 ? 76 : this.folder;
      api.createMyFolder(data).then(() => {
        this.mkdirVisible = false;
        this.init();
      });
    },
    showContextMenu(event) {
      // 阻止默认右键菜单
      event.preventDefault();
      if (!this.folderOperates) {
        return;
      }
      let data = {
        style: {
          top: event.clientY + 'px',
          left: event.clientX + 'px'
        },
        menus: this.folderOperates
      };
      this.$store.commit('showContextMenu', data);
    },
    hideContextMenu() {
      this.$store.commit('hideContextMenu');
    },
    reFetch() {
      this.selectedFiles = [];
      if (this.$refs.baFileList) {
        this.$refs.baFileList.fetchData();
      } else if (this.$refs.baFileTable) {
        this.$refs.baFileTable.fetchData();
      }
      if (this.$refs.innerFiles) {
        this.$refs.innerFiles.reFetch();
      }
    },
    init() {
      api.getFolderPath(this.folder).then(ret => {
        let path_crumb = [{
          name: '共享文件'
        }];
        for (let p of ret) {
          path_crumb.push({
            name: p.name,
            link: '/m/share/folder/battalion/' + p.folderId
          });
        }
        path_crumb[path_crumb.length - 1].link = null;
        this.crumb = path_crumb;
        if (path_crumb[path_crumb.length - 1].name.endsWith('大队')) {
          this.innerFolderTitle = '大队内共享';
        } else {
          this.innerFolderTitle = '站内共享';
        }
      });
      if (this.folder < 1) {
        api.getFilesBySuper({
          superFileId: 76,
          page: 0,
          pageSize: 100
        }).then(ret => {
          this.rows[0] = ret;
        });
      }
      api.getFolderOperates(this.folder).then(ret => {
        this.inner = ret.inner;
        if (!ret.ops) {
          this.folderOperates = null;
          return;
        }
        this.folderOperates = [];
        this.folderWrite = false;
        for (let op of ret.ops) {
          if (op == 'upload') {
            this.folderWrite = true;
          }
          this.folderOperates.push({
            name: ret.map[op],
            key: this.ek + op
          });
        }
      });
      this.reFetch();
    }
  },
  mounted() {
    this.init();
  }
};