import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}
const api = {
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret)
    },
    getQueryForm(formId, query) {
        return axios.get(path.baseUrl + path.queryForm[formId], {params: query})
    },
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    post(url, data) {
        return axios.post(path.baseUrl + url, data)
    },
    getDialogForm(dataId, bindId) {
        if (bindId != null) {
            return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
        }
        return axios.get(path.baseUrl + path.dialogs[dataId])
    },
    getMenus() {
        return axios.get(path.baseUrl + path.menus)
    },
    getUserInfo(accessToken) {
        console.log('getUserInfo accessToken', accessToken);
        return axios.get(path.baseUrl + path.userInfo)
    },
    getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout() {
        return axios.get(path.baseUrl + path.logout)
    },
    getBannerList(query) {
        return axios.get(path.baseUrl + path.getBannerList, {params: query})
    },
    createBanner(data) {
        return axios.post(path.baseUrl + path.createBanner, data);
    },
    updateBanner(data) {
        return axios.post(path.baseUrl + path.updateBanner, data);
    },
    deleteBanner(bannerId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteBanner, [bannerId]))
    },

    updateSystemConfig(data) {
        return axios.post(path.baseUrl + path.updateSystemConfig, data);
    },
    getSystemConfig() {
        return axios.get(path.baseUrl + path.getSystemConfig)
    },
    updateImageValue(data) {
        return axios.post(path.baseUrl + path.updateImageValue, data);
    },
    getMapKey() {
        return axios.get(path.baseUrl + path.getMapKey)
    },
    updateOrganizationEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateOrganizationEnableState, [id, val]))
    },
    createRole(data) {
        return axios.post(path.baseUrl + path.createRole, data);
    },
    updateUserEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserEnableState, [id, val]))
    },
    updateUserManagerLogin(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserManagerLogin, [id, val]))
    },
    getOrgTreeData() {
        return axios.get(path.baseUrl + path.getOrgTreeData)
    },
    createOrg(data) {
        return axios.post(path.baseUrl + path.createOrg, data);
    },
    deleteOrg(id) {
        return axios.get(path.baseUrl + stringFormat(path.deleteOrg, [id]))
    },
    updateOrg(data) {
        return axios.post(path.baseUrl + path.updateOrg, data);
    },
    createEnumValue(data) {
        return axios.post(path.baseUrl + path.createEnumValue, data);
    },
    updateEnumValueEnableState(dataId,value) {
        return axios.get(path.baseUrl + stringFormat(path.updateEnumValueEnableState, [dataId,value]))
    },
    getDataCatalogOrgList() {
        return axios.get(path.baseUrl + path.getDataCatalogOrgList)
    },
    readExcelSheet(url) {
        return axios.get(path.baseUrl + stringFormat(path.readExcelSheet, [url]))
    },
    getDataCatalogProperityList() {
        return this.getEnumOptionList(0)
    },
    getEnumOptionList(enumIndex) {
        return axios.get(path.baseUrl + stringFormat(path.getEnumOptionList, [enumIndex]))
    },
    createCatalog(data) {
        return axios.post(path.baseUrl + path.createCatalog, data);
    },
    getDataCatalogGroupByProperty(query) {
        return axios.get(path.baseUrl + path.getDataCatalogGroupByProperty, {params: query})
    },
    getCatalogById(catalogId) {
        return axios.get(path.baseUrl + stringFormat(path.getCatalogById, [catalogId]))
    },
    searchCatalogByName(data) {
        return axios.post(path.baseUrl + path.searchCatalogByName, data);
    },
    getDashboardData() {
        return axios.get(path.baseUrl + path.getDashboardData)
    },
    getVersionDownloadInfo(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getVersionDownloadInfo, [versionId]))
    },
    orgCatalogData(query) {
        return axios.get(path.baseUrl + path.orgCatalogData, {params: query})
    },
    getDataIncrease() {
        return axios.get(path.baseUrl + path.getDataIncrease)
    },
    getCatalogPropertyData() {
        return axios.get(path.baseUrl + path.getCatalogPropertyData)
    },
    getLastestShare(number) {
        return axios.get(path.baseUrl + stringFormat(path.getLastestShare, [number]))
    },
    getVersionReadOnlyToken(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getVersionReadOnlyToken, [versionId]))
    },
    getEditorInfoByFileKey(fileKey) {
        return axios.get(path.baseUrl + stringFormat(path.getEditorInfoByFileKey, [fileKey]))
    },
    readFileType(data) {
        return axios.post(path.baseUrl + path.readFileType, data);
    },
    changeFileVersionShareState(query) {
        return axios.get(path.baseUrl + path.changeFileVersionShareState, {params: query})
    },
    changeCatalogShareState(query) {
        return axios.get(path.baseUrl + path.changeCatalogShareState, {params: query})
    },
    createNonExcelCatalog(data) {
        return axios.post(path.baseUrl + path.createNonExcelCatalog, data);
    },
    createNewVersion(data) {
        return axios.post(path.baseUrl + path.createNewVersion, data);
    },
    deleteVersion(id) {
        return axios.get(path.baseUrl + stringFormat(path.deleteVersion, [id]))
    },
    getCooEditToken(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getCooEditToken, [versionId]))
    },
    getFileEditHistory(query) {
        return axios.get(path.baseUrl + path.getFileEditHistory, {params: query})
    },
    getFileEditHistoryVersion(query) {
        return axios.get(path.baseUrl + path.getFileEditHistoryVersion, {params: query})
    },
    collectOrDisCollect(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.collectOrDisCollect, [versionId]))
    },
    getCreateTaskInitData() {
        return axios.get(path.baseUrl + path.getCreateTaskInitData)
    },
    getCooEditConfig(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getCooEditConfig, [versionId]))
    },
    getCooEditOrgPeopleConfig(query) {
        return axios.get(path.baseUrl + path.getCooEditOrgPeopleConfig, {params: query})
    },
    editVersionAuth(query) {
        return axios.get(path.baseUrl + path.updateVersionAuth, {params: query})
    },
    updateCooEditOrgAuth(query) {
        return axios.get(path.baseUrl + path.updateCooEditOrgAuth, {params: query})
    },
    searchCooEditUser(data) {
        return axios.post(path.baseUrl + path.searchCooEditUser, data);
    },
    getVersionEditHistory(versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getVersionEditHistory, [versionId]))
    },
    deleteEditHistory(id) {
        return axios.get(path.baseUrl + stringFormat(path.deleteEditHistory, [id]))
    },
    getCatalogVersionsById(catalogId) {
        return axios.get(path.baseUrl + stringFormat(path.getCatalogVersionsById, [catalogId]))
    },
    getUserTree() {
        return axios.get(path.baseUrl + path.getUserTree)
    },
    createCatalogTask(data) {
        return axios.post(path.baseUrl + path.createCatalogTask, data);
    },
    getTaskDetail(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.getTaskDetail, [taskId]))
    },
    confirmFinishCoEdit(participantId) {
        return axios.get(path.baseUrl + stringFormat(path.confirmFinishCoEdit, [participantId]))
    },
    getCooEditTokenOrReadToken(taskId,versionId) {
        return axios.get(path.baseUrl + stringFormat(path.getCooEditTokenOrReadToken, [taskId,versionId]))
    },
    auditTask(query) {
        return axios.get(path.baseUrl + path.auditTask, {params: query})
    },
    storeTask(query) {
        return axios.get(path.baseUrl + path.storeTask, {params: query})
    },
    getTaskEditInfo(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.getTaskEditInfo, [taskId]))
    },
    getCheckedUsers(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.getCheckedUsers, [taskId]))
    },
    updateCatalogTask(data) {
        return axios.post(path.baseUrl + path.updateCatalogTask, data);
    },
    getMeetingInfoById(meetingId) {
        return axios.get(path.baseUrl + stringFormat(path.getMeetingInfoById, [meetingId]))
    },
    updateMeetingAttachments(data) {
        return axios.post(path.baseUrl + path.updateMeetingAttachments, data);
    },
    updateTaskAttachments(data) {
        return axios.post(path.baseUrl + path.updateTaskAttachments, data);
    },
    getCreateMeetingInitData() {
        return axios.get(path.baseUrl + path.getCreateMeetingInitData)
    },
    getMeetingCheckedUsers(meetingId) {
        return axios.get(path.baseUrl + stringFormat(path.getMeetingCheckedUsers, [meetingId]))
    },
    createMeeting(data) {
        return axios.post(path.baseUrl + path.createMeeting, data);
    },
    updateMeeting(data) {
        return axios.post(path.baseUrl + path.updateMeeting, data);
    },
    getMeetingEditInfoById(meetingId) {
        return axios.get(path.baseUrl + stringFormat(path.getMeetingEditInfoById, [meetingId]))
    },
    cancelTask(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.cancelTask, [taskId]))
    },
    completeTask(taskId) {
        return axios.get(path.baseUrl + stringFormat(path.completeTask, [taskId]))
    },
    cancelMeeting(meetingId) {
        return axios.get(path.baseUrl + stringFormat(path.cancelMeeting, [meetingId]))
    },
    completeMeeting(meetingId) {
        return axios.get(path.baseUrl + stringFormat(path.completeMeeting, [meetingId]))
    },
    getFilesBySuper(query) {
        return axios.get(path.baseUrl + path.getFilesBySuper, {params: query})
    },
    getMyFile(query) {
        return axios.get(path.baseUrl + path.getMyFile, {params: query})
    },
    createMyFolder(data) {
        return axios.post(path.baseUrl + path.createMyFolder, data);
    },
    deleteFile(fileId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteFile, [fileId]))
    },
    createMyFile(data) {
        return axios.post(path.baseUrl + path.createMyFile, data);
    },
    getFileOperates() {
        return axios.get(path.baseUrl + path.getFileOperates)
    },
    setFileShareState(query) {
        return axios.get(path.baseUrl + path.setFileShareState, {params: query})
    },
    getFileDownloadLink(query) {
        return axios.get(path.baseUrl + path.getFileDownloadLink, {params: query})
    },
    getFolderPath(folder) {
        if(!folder){
            folder = 0;
        }
        return axios.get(path.baseUrl + stringFormat(path.getFolderPath, [folder]))
    },
    moveFile(query) {
        return axios.get(path.baseUrl + path.moveFile, {params: query})
    },
    getFilePreviewLink(query) {
        return axios.get(path.baseUrl + path.getFilePreviewLink, {params: query})
    },
    getFolderOperates(folder) {
        if(!folder){
            folder = 0;
        }
        return axios.get(path.baseUrl + stringFormat(path.getFolderOperates, [folder]))
    },
    collectFile(fileId) {
        return axios.get(path.baseUrl + stringFormat(path.collectFile, [fileId]))
    },
    getFileMoreInfo(fileId) {
        return axios.get(path.baseUrl + stringFormat(path.getFileMoreInfo, [fileId]))
    },
    getFeedbackList(fileId) {
        return axios.get(path.baseUrl + stringFormat(path.getFeedbackList, [fileId]))
    },
    mergeDownload(fileIds) {
        return axios.get(path.baseUrl + stringFormat(path.mergeDownload, [fileIds]))
    },
    getShareFolderBySuperIds(superIds) {
        return axios.get(path.baseUrl + stringFormat(path.getShareFolderBySuperIds, [superIds]))
    },
    getOpenShareFilesByFolder(folderId) {
        return axios.get(path.baseUrl + stringFormat(path.getOpenShareFilesByFolder, [folderId]))
    },
    searchShareFileByName(data) {
        return axios.post(path.baseUrl + path.searchShareFileByName, data);
    },
    updateLimitAccessUser(data) {
        return axios.post(path.baseUrl + path.updateLimitAccessUser, data);
    },
    getAccessLimit(folderId) {
        return axios.get(path.baseUrl + stringFormat(path.getAccessLimit, [folderId]))
    },
    arrangeFolder(query) {
        return axios.get(path.baseUrl + path.arrangeFolder, {params: query})
    },
    batchCreateMyFile(data) {
        return axios.post(path.baseUrl + path.batchCreateMyFile, data);
    },
    updateOrgTreeIndex(data) {
        return axios.post(path.baseUrl + path.updateOrgTreeIndex, data);
    },
    getIceUploadToken(query) {
        return axios.get(path.baseUrl + path.getIceUploadToken, {params: query})
    },
    getCooEditTaskList(data) {
        return axios.post(path.baseUrl + path.getCooEditTaskList, data);
    },
    getResourceGrowthChartData(query) {
        return axios.get(path.baseUrl + path.getResourceGrowthChartData, {params: query})
    },
    getDingQrLoginIframeUrl() {
        return axios.get(path.baseUrl + path.getDingQrLoginIframeUrl)
    },
    loginByDingAuthCode(code) {
        return axios.get(path.baseUrl + stringFormat(path.loginByDingAuthCode, [code]))
    },
    bindDingByToken(dingToken) {
        return axios.get(path.baseUrl + stringFormat(path.bindDingByToken, [dingToken]))
    }
}

export default api;