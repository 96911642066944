import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e5408f48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ProactivelyReportFormContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_step = _resolveComponent("el-step");
  const _component_el_steps = _resolveComponent("el-steps");
  const _component_SheetEditor = _resolveComponent("SheetEditor");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_steps, {
    active: $data.activeSheet,
    "align-center": "",
    "finish-status": "success",
    "process-status": "finish"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (catalog, ci) => {
      return _openBlock(), _createBlock(_component_el_step, {
        onClick: $event => $options.changeTab(ci),
        title: `目录 ${ci + 1}`,
        key: ci,
        description: catalog.sheetName
      }, null, 8, ["onClick", "title", "description"]);
    }), 128))]),
    _: 1
  }, 8, ["active"]), _createVNode(_component_SheetEditor, {
    data: $props.data[$data.activeSheet],
    onNextSheet: $options.onNextSheet
  }, null, 8, ["data", "onNextSheet"])]);
}