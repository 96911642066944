export default {
  name: "ArrangeOperate",
  data() {
    return {
      method: 'byMonth',
      keyword: ''
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};