import { ElLoading, ElMessage } from "element-plus";
import api from "@/api";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "FileOperateHead",
  components: {
    ButtonDialog
  },
  props: {
    selectedFiles: {
      type: Array,
      default: () => {
        return null;
      }
    },
    write: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: null
    };
  },
  methods: {
    batchCopy() {
      this.$refs.copyFile.openDialog();
    },
    batchMove() {
      this.$refs.moveFile.openDialog();
    },
    deleteSelected() {
      let thiz = this;
      for (let f of this.selectedFiles) {
        api.deleteFile(f).then(() => {
          ElMessage.warning('已删除');
          setTimeout(thiz.$emit('fresh'), 200);
        });
      }
    },
    mergeDownload() {
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.mergeDownload(this.selectedFiles.join(',')).then(ret => {
        window.open(ret, '_blank');
        // this.selectedFiles = [];
      }).finally(() => {
        loading.close();
      });
    },
    onSearch() {
      this.$emit('search', this.search);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};