import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2c48dedf"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ContextMenuContainer colFlex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.contextMenu.menus, m => {
    return _openBlock(), _createBlock(_component_el_row, {
      key: m.key,
      onClick: $event => $options.fireEvent(m.key),
      class: "ft14 menuItem ptr"
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(m.name), 1)]),
      _: 2
    }, 1032, ["onClick"]);
  }), 128))]);
}