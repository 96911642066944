import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5a9575cb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ProactivelyReportingViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_router_link = _resolveComponent("router-link");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_CatalogUploadProcess = _resolveComponent("CatalogUploadProcess");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 5,
    ref: "fileTable",
    onAddMore: $options.onAddMore
  }, {
    fileType: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    cooEditDataType: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    name: _withCtx(data => [_createVNode(_component_router_link, {
      to: '/m/catalog/detail/' + data.data.id,
      style: {
        "text-decoration": "none",
        "color": "#1388ff"
      }
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(data.rowData), 1)]),
      _: 2
    }, 1032, ["to"])]),
    _: 1
  }, 8, ["onAddMore"])]), _createVNode(_component_CatalogUploadProcess, {
    ref: "uploadProcess",
    onProcessComplete: $options.onProcessComplete
  }, null, 8, ["onProcessComplete"])], 64);
}