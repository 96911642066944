import api from "@/api";
import CoEditParticipant from "@/components/catalog/CoEditParticipant";
import AttachmentFiles from "@/components/AttachmentFiles";
export default {
  name: "MeetingDetailView",
  components: {
    AttachmentFiles,
    CoEditParticipant
  },
  data() {
    return {
      now: new Date().getTime(),
      types: {
        MEETING: '会议',
        ACTIVITY: '活动'
      },
      meetingId: this.$route.params.meetingId,
      meeting: {},
      participants: [],
      owner: false,
      step: 1,
      crumb: [{
        name: '会议管理',
        link: '/m/meeting/list'
      }, {
        name: '会议详情'
      }]
    };
  },
  methods: {
    cancel() {
      api.cancelMeeting(this.meetingId).then(() => {
        this.init();
      });
    },
    complete() {
      api.completeMeeting(this.meetingId).then(() => {
        this.init();
      });
    },
    updateAttachment(files) {
      api.updateMeetingAttachments({
        meetingId: this.meetingId,
        attachments: files
      }).then(() => {
        this.init();
      });
    },
    init() {
      api.getMeetingInfoById(this.meetingId).then(ret => {
        this.meeting = ret.meeting;
        this.participants = ret.participants;
        this.owner = ret.owner;
        this.step = ret.step;
      });
    }
  },
  mounted() {
    this.init();
  }
};