import "core-js/modules/es.array.push.js";
import api from "@/api";
import IceFolder from "@/components/catalog/IceFolder";
import constants from "@/utils/constants";
import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "ShareFileListDashboardView",
  components: {
    IceFolder,
    DialogCustomView
  },
  data() {
    return {
      search: null,
      previewUrl: null,
      previewVisible: false,
      loading: false,
      options: [],
      folders: [],
      departmentFolder: true,
      subFiles: {},
      activeFolderId: 0,
      activeFolderName: null
    };
  },
  methods: {
    iconOf(url) {
      return constants.getFileIcon(url);
    },
    typeNameOf(url) {
      return constants.getFileType(url);
    },
    onClickFile(f) {
      if (f.fileType == 'FOLDER') {
        this.getFolderContent(f);
      } else {
        api.getFilePreviewLink({
          fileId: f.id
        }).then(ret => {
          if (ret.type == 'image') {
            this.previewUrl = ret.url;
            this.previewVisible = true;
          } else if (ret.type == 'url') {
            window.open(ret.url, '_blank');
          }
        });
      }
    },
    getFolderContent(folder) {
      this.activeFolderId = folder.id;
      this.activeFolderName = folder.name;
      api.getOpenShareFilesByFolder(folder.id).then(ret => {
        let subFiles = [];
        for (let g of ret) {
          for (let s of g.subFiles) {
            subFiles.push(s);
          }
        }
        this.subFiles[folder.id] = subFiles;
        console.log('subFiles', this.subFiles);
      });
    },
    gotoSearchView() {
      this.$router.push('/m/share/folder/search?name=' + this.search);
    },
    goToFolder(folder) {
      if (this.departmentFolder) {
        this.$router.push('/m/share/folder/org/' + folder);
      } else {
        this.$router.push('/m/share/folder/battalion/' + folder);
      }
    },
    onSearchResultChange(e) {
      if (e.length < 1) {
        return;
      }
      this.$router.push('/m/share/folder/org/' + e[0]);
    },
    remoteSearch(v) {
      api.searchShareFileByName({
        name: v
      }).then(ret => {
        this.options = ret;
      });
    },
    init() {
      api.getShareFolderBySuperIds(75).then(ret => {
        this.folders = ret;
        if (ret.length > 0) {
          this.getFolderContent(ret[0]);
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};