import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "mt16"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "primaryBtn",
      icon: 'plus',
      onClick: $options.addMore
    }, {
      default: _withCtx(() => [_createTextVNode("新增角色")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "2",
      ref: "roleTable"
    }, null, 512)]),
    _: 1
  }), _createVNode(_component_DialogView, {
    "data-id": 5,
    onSubmit: $options.createRole,
    "visible-control": $data.createVisible,
    onClose: _cache[0] || (_cache[0] = $event => $data.createVisible = false)
  }, null, 8, ["onSubmit", "visible-control"])], 64);
}