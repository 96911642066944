import api from "@/api";
import DialogView from "@/components/DialogView";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "TrainTopicSettingView",
  components: {
    DialogView,
    BreadCrumb
  },
  data() {
    return {
      enableMap: {},
      visibleMap: {},
      createVisible: false,
      editVisible: false,
      orgTree: [],
      superId: null,
      editId: null,
      filterText: null,
      crumb: [{
        name: '组织管理'
      }]
    };
  },
  watch: {
    filterText(newValue) {
      this.$refs.treeView.filter(newValue);
    }
  },
  methods: {
    handleDrop(draggingNode, dropNode) {
      api.updateOrgTreeIndex({
        ids: dropNode.parent.childNodes.map(n => n.data.id)
      });
    },
    allowDropCheck(draggingNode, dropNode, type) {
      // 禁止叶子节点成为其他节点的子节点
      if (type === 'inner') {
        return false;
      }
      // 禁止拖动节点被拖出其父节点
      if (dropNode.parent != draggingNode.parent) {
        return false;
      }
      // 禁止节点被拖到其子孙节点下
      if (dropNode.descendants && dropNode.descendants.indexOf(draggingNode) !== -1) {
        return false;
      }
      // 其他情况允许放置
      return true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.includes(value);
    },
    updateOrg(data) {
      data.id = this.editId;
      api.updateOrg(data).then(() => {
        this.editVisible = false;
        this.init();
      });
    },
    deleteOrg(data) {
      api.deleteOrg(data.id).then(() => {
        this.init();
      });
    },
    createOrg(data) {
      data.superId = this.superId;
      api.createOrg(data).then(() => {
        this.createVisible = false;
        this.init();
      });
    },
    createSub(data) {
      this.superId = data.id;
      this.createVisible = true;
    },
    editOrg(data) {
      this.editId = data.id;
      this.editVisible = true;
    },
    enableChange(id, val) {
      this.enableMap[id] = val;
      api.updateOrganizationEnableState(id, val);
    },
    onFetchData(d) {
      for (let itm of d.list) {
        this.enableMap[itm.id] = itm.enable;
        this.visibleMap[itm.id] = itm.visible;
      }
    },
    init() {
      api.getOrgTreeData().then(data => {
        this.orgTree = [data];
      });
    }
  },
  mounted() {
    this.init();
  }
};