export default {
  name: "NumberCardWithSelect",
  props: {
    title: {
      type: String,
      default: '-'
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      timeType: '0',
      selectedLabel: 0,
      times: {
        0: '本月',
        1: '季度',
        2: '半年',
        3: '年'
      },
      timesKey: {
        0: 'month',
        1: 'month3',
        2: 'month6',
        3: 'year'
      }
    };
  },
  methods: {
    onTimeChange(e) {
      this.selectedLabel = e;
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};