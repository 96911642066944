import "core-js/modules/es.array.push.js";
import api from "@/api";
import DialogView from "@/components/DialogView";
import VersionCooEditAuthView from "./VersionCooEditAuthView";
import VersionEditHistory from "@/components/catalog/VersionEditHistory";
export default {
  name: "CatalogVersionTable",
  components: {
    VersionEditHistory,
    DialogView,
    VersionCooEditAuthView
  },
  props: {
    owner: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      createNewVersionVisible: false,
      cooDrawerVisible: false,
      historyVersionDrawerVisible: false,
      preview: false,
      createNewVersionId: null,
      cooEditVersionId: null
    };
  },
  methods: {
    collectOrDisCollect(versionId) {
      api.collectOrDisCollect(versionId).then(() => {
        this.$emit('refresh');
      });
    },
    submitNewVersion(data) {
      data.versionId = this.createNewVersionId;
      api.createNewVersion(data).then(() => {
        this.createNewVersionVisible = false;
        this.$emit('refresh');
      });
    },
    createNewVersion(oldId) {
      this.createNewVersionId = oldId;
      this.createNewVersionVisible = true;
    },
    configCooEdit(vid) {
      this.cooEditVersionId = vid;
      this.cooDrawerVisible = true;
    },
    viewVersionEditHistory(vid) {
      this.cooEditVersionId = vid;
      this.historyVersionDrawerVisible = true;
    },
    gotoCooEdit(versionId) {
      api.getCooEditToken(versionId).then(ret => {
        window.location.href = ret;
      });
    },
    onChangeShareState(vid, share) {
      api.changeFileVersionShareState({
        id: vid,
        'share': share
      }).then(() => {
        this.$emit('refresh');
      });
    },
    deleteVersion(v) {
      api.deleteVersion(v.id).then(() => {
        this.$emit('refresh');
      });
    },
    viewFile(version) {
      api.getVersionReadOnlyToken(version.id).then(ret => {
        this.$router.push('/m/office/editor/' + ret);
      });
    },
    download(version) {
      api.getVersionDownloadInfo(version.id).then(ret => {
        window.open(ret.sourceFileUrl, '_blank');
      });
    },
    previewImage() {},
    init() {}
  },
  mounted() {
    this.init();
  }
};