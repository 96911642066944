import VersionEditHistoryCard from "@/components/catalog/VersionEditHistoryCard";
import api from "@/api";
export default {
  name: "VersionEditHistory",
  components: {
    VersionEditHistoryCard
  },
  props: {
    versionId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selected: 0,
      histories: []
    };
  },
  methods: {
    init() {
      api.getVersionEditHistory(this.versionId).then(ret => {
        this.histories = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};