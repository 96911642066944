import OrgSelectorItem from "@/components/catalog/OrgSelectorItem";
export default {
  name: "OrgSelector",
  components: {
    OrgSelectorItem
  },
  props: {
    versionId: {
      type: Number,
      default: 0
    },
    selectedOrgId: {
      type: Number,
      default: 0
    },
    orgs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};