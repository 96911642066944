import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-62744c27"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_BatterTableDataView, {
    "table-id": 3,
    ref: "userTable",
    onOnFetchData: $options.onFetchData
  }, {
    avatar: _withCtx(data => [_createElementVNode("img", {
      src: data.rowData,
      style: {
        "width": "50px"
      }
    }, null, 8, _hoisted_1)]),
    enable: _withCtx(data => [_createVNode(_component_el_switch, {
      "active-color": "#0078D7",
      "model-value": $data.enableMap[data.rowId],
      onChange: v => $options.enableChange(data.rowId, v)
    }, null, 8, ["model-value", "onChange"])]),
    enableManagerLogin: _withCtx(data => [_createVNode(_component_el_switch, {
      "active-color": "#0078D7",
      "model-value": $data.loginMap[data.rowId],
      onChange: v => $options.enableManagerLoginChange(data.rowId, v)
    }, null, 8, ["model-value", "onChange"])]),
    _: 1
  }, 8, ["onOnFetchData"])]);
}