import SheetEditor from "@/components/catalog/SheetEditor";
export default {
  name: "WorkBookEditor",
  components: {
    SheetEditor
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [{
          sheetIndex: 0,
          sheetName: "消防车辆",
          colSize: 55,
          sheetRowIndex: 0,
          colNames: []
        }];
      }
    }
  },
  data() {
    return {
      activeSheet: 0,
      sheets: []
    };
  },
  methods: {
    changeTab(idx) {
      if (idx > this.activeSheet) {
        return;
      }
      this.activeSheet = idx;
    },
    onNextSheet(data) {
      this.sheets[this.activeSheet] = data;
      if (this.activeSheet + 1 < this.data.length) {
        this.activeSheet += 1;
      } else {
        this.$emit('completeSheetEdit', this.sheets);
      }
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};