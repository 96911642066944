import api from "@/api";
export default {
  name: "CatalogInfoTable",
  components: {},
  props: {
    isOwner: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    owner: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      share: false,
      dataQuality: {
        BAD: "差",
        NORMAL: "一般",
        GOOD: "良好",
        PERFECT: "高"
      },
      updateFrequency: {
        DAY: "天",
        WEEK: "周",
        MONTH: "月",
        YEAR: "年",
        NOT_SURE: "不定"
      },
      preview: false
    };
  },
  methods: {
    onChangeShareState(e) {
      api.changeCatalogShareState({
        id: this.data.id,
        share: e
      }).then(() => {
        this.$emit('refresh');
      });
    }
  }
};