import "core-js/modules/es.array.push.js";
import DialogCustomView from "@/components/DialogCustomView";
import api from "@/api";
export default {
  name: "FilePreviewer",
  components: {
    DialogCustomView
  },
  props: {
    pathPrefix: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      previewVisible: false,
      previewUrl: null
    };
  },
  methods: {
    preview(file) {
      console.log('fuck preview', file);
      if (file.fileType === 'FILE' || file.fileType === 'FILE_LINK') {
        api.getFilePreviewLink({
          fileId: file.id
        }).then(ret => {
          if (ret.type == 'image') {
            this.previewUrl = ret.url;
            this.previewVisible = true;
          } else if (ret.type == 'url') {
            window.open(ret.url, '_blank');
          }
        });
      } else {
        this.$router.push(this.pathPrefix + file.id);
      }
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};