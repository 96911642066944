import BreadCrumb from "@/components/BreadCrumb";
import api from "@/api";
import CoEditParticipant from "@/components/catalog/CoEditParticipant";
import DialogCustomView from "@/components/DialogCustomView";
import AttachmentFiles from "@/components/AttachmentFiles";
export default {
  name: "CatalogTaskDetailView",
  components: {
    DialogCustomView,
    BreadCrumb,
    CoEditParticipant,
    AttachmentFiles
  },
  data() {
    return {
      taskId: this.$route.params.taskId,
      crumb: [{
        name: '任务管理',
        link: '/m/task/list'
      }, {
        name: '任务详情'
      }],
      task: {},
      versions: [],
      participants: [],
      catalog: {},
      version: {},
      owner: false,
      step: 1,
      auditVisible: false,
      auditResult: null,
      repeatTypes: {}
    };
  },
  computed: {
    notFinished() {
      return this.participants.filter(p => p.state !== 'FINISHED').length > 0;
    }
  },
  methods: {
    updateAttachment(files) {
      api.updateTaskAttachments({
        taskId: this.taskId,
        attachments: files
      }).then(() => {
        this.init();
      });
    },
    confirmAudit() {
      api.auditTask({
        taskId: this.taskId,
        result: this.auditResult
      }).then(() => {
        this.auditVisible = false;
        this.init();
      });
    },
    store() {
      api.storeTask({
        taskId: this.taskId
      }).then(() => {
        this.init();
      });
    },
    cancel() {
      api.cancelTask(this.taskId).then(() => {
        this.init();
      });
    },
    complete() {
      api.completeTask(this.taskId).then(() => {
        this.init();
      });
    },
    audit() {
      this.auditVisible = true;
    },
    gotoEditFile(versionId) {
      api.getCooEditTokenOrReadToken(this.taskId, versionId).then(ret => {
        if (ret.startsWith('http')) {
          window.open(ret, '_blank');
        } else {
          window.open('/#/m/office/editor/' + ret);
        }
      });
    },
    init() {
      api.getTaskDetail(this.taskId).then(ret => {
        this.task = ret.task;
        this.versions = ret.versions;
        this.repeatTypes = ret.repeatTypes;
        this.participants = ret.participants;
        this.catalog = ret.catalog;
        this.version = ret.version;
        this.owner = ret.owner;
        this.step = ret.step >= 5 ? 6 : ret.step;
      });
    }
  },
  mounted() {
    this.init();
  }
};