import api from "@/api";
export default {
  name: "OrgPeopleItem",
  props: {
    versionId: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      checked: false
    };
  },
  methods: {
    onCheckChange() {
      api.editVersionAuth({
        phone: this.data.phone,
        versionId: this.versionId
      }).then(() => {
        this.$emit('AuthChange');
      });
    },
    init() {
      this.checked = this.data.state > 0;
    }
  },
  mounted() {
    this.init();
  }
};