import * as echarts from 'echarts';
import api from "@/api";
export default {
  name: "ResourceGrowthChart",
  data() {
    return {
      data: [],
      btn: 14
    };
  },
  methods: {
    init() {
      api.getResourceGrowthChartData().then(ret => {
        this.data = ret;
        this.render(14);
      });
    },
    changeDate(d) {
      this.btn = d;
      this.render(d);
    },
    render(size) {
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['文件数', '文件夹数'],
          right: '1%'
        },
        grid: {
          top: '10%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.data.slice(0, size).sort((a, b) => a.summaryDate - b.summaryDate).map(d => d.summaryDate.toString().substring(4))
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '文件数',
          type: 'line',
          data: this.data.slice(0, size).sort((a, b) => a.summaryDate - b.summaryDate).map(d => d.fileCnt),
          lineStyle: {
            color: '#015EF5'
          }
        }, {
          name: '文件夹数',
          type: 'line',
          data: this.data.slice(0, size).sort((a, b) => a.summaryDate - b.summaryDate).map(d => d.folderCnt),
          lineStyle: {
            color: '#26B397'
          }
        }]
      };
      let bar = document.getElementById('resourceGrowthChartContainer');
      let exist = echarts.getInstanceByDom(bar);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(bar);
      chart.setOption(option);
    }
  },
  mounted() {
    this.init();
  }
};